import * as React from 'react';
import styled from 'styled-components';
import { Text, CardHeader } from '@amzn/storm-ui';
import { ObjectivesCard } from './ObjectivesCard';
import { Deck } from 'types/Deck';
import { DimensionsCard } from 'containers/CreateDeckContainer';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { sortDecks } from 'utils/deck.utils';

const ObjectivesPanelContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

const ObjectivesCardContainer = styled.div`
    flex: 0 0 100%;
    padding: 10px;
`;

interface ObjectivesPanelProps {
    handleDeckSelection: (id: number) => void;
    decks: Deck[] | undefined;
    loading: boolean;
}

export const NON_SELECTED_DECK = -1;

export const ObjectivesPanel: React.FunctionComponent<ObjectivesPanelProps> = (
    props: ObjectivesPanelProps
) => {
    const [selectedDeckId, setSelectedDeckId] =
        React.useState<number>(NON_SELECTED_DECK);

    const { handleDeckSelection, decks, loading } = props;

    const handleDeckSelect = (id: number): void => {
        if (selectedDeckId !== id) {
            setSelectedDeckId(id);

            //Handle deck selection here
            handleDeckSelection(id);
        }
    };

    const ObjectivesViewer: React.FunctionComponent = () => {
        if (decks === undefined) {
            return (
                <Text data-testid="decksPanelHelpText">
                    Once you have provided your inputs above available decks
                    will appear here after clicking
                    <strong> Apply Settings</strong>.
                </Text>
            );
        } else if (decks.length === 0) {
            return <Text>No Decks</Text>;
        } else {
            return (
                <>
                    {sortDecks(decks).map((deck) => {
                        const {
                            id,
                            name,
                            description,
                            objective,
                            recommended,
                            sourceVersion,
                            previewLocation,
                        } = deck;
                        return (
                            <ObjectivesCardContainer
                                key={id}
                                id={`objectivesCard-${id}`}
                                data-testid={`objectivesCard-${id}`}
                            >
                                <ObjectivesCard
                                    id={id}
                                    name={name}
                                    description={description}
                                    objective={objective}
                                    recommended={recommended}
                                    sourceVersion={sourceVersion}
                                    previewLocation={previewLocation}
                                    isSelected={selectedDeckId === id}
                                    handleDeckSelect={handleDeckSelect}
                                />
                            </ObjectivesCardContainer>
                        );
                    })}
                </>
            );
        }
    };

    return (
        <DimensionsCard>
            <CardHeader>
                <Text type="h2">Decks</Text>
            </CardHeader>
            <ObjectivesPanelContainer>
                {loading ? <LoadingSpinner /> : <ObjectivesViewer />}
            </ObjectivesPanelContainer>
        </DimensionsCard>
    );
};
