import { Icon, AnchoredAlert, Tag } from '@amzn/storm-ui';
import styled from 'styled-components';
import React, { useRef } from 'react';

const HelpTipTag = styled(Tag)`
    margin: 0;
    bottom: 0;
    display: block;
    padding: 2px 10px;
    cursor: pointer;
`;

export const DetailPopoverViewer = styled('div')`
    maxheight: 250;
    text-align: left;
`;

export interface DetailPopoverProps extends React.PropsWithChildren {
    isOpen: boolean;
    open: () => void;
    label: string;
    position?: 'top' | 'bottom';
    renderInPortalDiv?: boolean;
}

export const DetailPopover: React.FunctionComponent<DetailPopoverProps> = (
    props: DetailPopoverProps
) => {
    const { isOpen, open, label, children, position, renderInPortalDiv } =
        props;
    let portalRef = undefined;
    if (renderInPortalDiv) {
        const portalElement = document.getElementById('portal');
        portalRef = portalElement ? useRef(portalElement) : undefined;
    }

    return (
        <AnchoredAlert
            isOpen={isOpen}
            onClick={open}
            onCloseButtonClick={open}
            align="center"
            type="light"
            trigger={
                <HelpTipTag
                    type="beta"
                    data-testid={`view-${label}`}
                    label={
                        <>
                            {label} <Icon type="question-circle" size="sm" />
                        </>
                    }
                    description={`View ${label}`}
                />
            }
            position={position ?? 'top'}
            portalRef={portalRef}
        >
            {children}
        </AnchoredAlert>
    );
};

export default DetailPopover;
