import {
    DimensionStatus,
    useDimensionStatusStore,
} from 'store/dimension.status';
import {
    BaseDimensionValue,
    DimensionValue,
    dimensionValueStore,
    useDimensionValueStore,
} from 'store/dimension.value';
import * as R from 'remeda';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import { useEffect } from 'react';
import { InputParameterFilter, RequestParameters, Filter } from 'types/Common';
import { buildQueryParameterFilter } from './app.utils';

/**
 * Given a list of dimensions check if all statuses are allValid
 *
 * @param dimensions: list of dimensions
 */
export function useCheckDimensionStatus(dimensions: string[]): boolean {
    const dimensionStatuses: DimensionStatus[] = useDimensionStatusStore(
        (state) => dimensions.map((d) => state[d])
    ).filter(R.isDefined);

    if (dimensionStatuses.length !== dimensions.length) {
        return false;
    }
    const allValid = !dimensionStatuses.some(
        (d) => d !== DimensionStatus.SUCCESS
    );
    return allValid;
}

/**
 * Merges all request parameter objects into one
 *
 * @param dimensions - list of dimensions to merge
 */
export function useGetRequestParameters(
    dimensions: string[]
): RequestParameters {
    const values = useDimensionValueStore((state) => [
        ...dimensions.map((d) => state[d]),
    ]);
    const requestParameters = R.pipe(
        values,
        R.filter(R.isDefined),
        R.map((e) => e.requestParameters),
        R.mergeAll
    ) as RequestParameters;
    return requestParameters;
}

/**
 * Provides function to set dimension value
 *
 * @param dimension - name of dimension
 * @param processRequestParameter - creates a request parameters for dimension
 */
export function useSetDimensionValue<T extends BaseDimensionValue>(
    dimension: string,
    processRequestParameter: (data: T) => RequestParameters
): [DimensionValue, (data: T) => void] {
    const [dimensionData, setData] = useDimensionValueStore((state) => [
        state.getDimensionData(dimension),
        state.setData,
    ]);
    const setDimensionData = (data: T) => {
        setData(dimension, data, processRequestParameter(data));
    };
    return [dimensionData, setDimensionData];
}

/**
 * Computes if dimension needs to be disabled
 *
 * @param dependencies - list of dependent dimensions
 */
export function useGetDimensionDisable(dependencies: string[]) {
    const allValid = useCheckDimensionStatus(dependencies);
    return !allValid;
}

/**
 * Sets up a useEffect hook which listens on a dimension value
 * When value changes, new dimension status is computed
 * @typedef T - should be a dimension value type that extends base dimension value
 * @param dimension - name of dimension
 * @param processDimensionStatus - function that computes and returns DimensionStatus
 */
export function useUpdateStatusOnChange<T extends BaseDimensionValue>(
    dimension: string,
    processDimensionStatus: (v: T) => DimensionStatus
) {
    const [dimensionData] = useDimensionValueStore((state) => [
        state[dimension],
    ]);
    const [setStatus] = useDimensionStatusStore((state) => [state.setStatus]);
    useEffect(() => {
        if (dimensionData) {
            dimensionData.data;
            setStatus(
                dimension,
                processDimensionStatus(dimensionData.data as any)
            ); // union typing issue :/
        }
    }, [dimensionData]);
}

export function getDimensionValues(
    dimensions: string[]
): (string | undefined)[] {
    const values = useDimensionValueStore((state) =>
        dimensions.map((e) => state[e]?.data.value)
    );
    return values;
}

/**
 * Given a list of dependencies, when depencency values change clear current value
 * @param dimension - name of dimension
 * @param dependencies - list of dependant dimensions
 */
export function clearDimensionValueOnDependencyChange(
    dimension: string,
    dependencies: string[]
) {
    const values = getDimensionValues(dependencies);
    const setData = useDimensionValueStore((state) => state.setData);
    useUpdateEffect(() => {
        setData(dimension, { value: '' }, {});
    }, values);
}

/**
 * Given a list of parameter filters fetch dimension values and create filter list
 */
export function buildQueryParamFiltersFromDimensions(
    filters: InputParameterFilter[]
): Filter[] {
    return R.pipe(
        filters,
        R.map((e) => ({
            ...e,
            value: dimensionValueStore.getState()[e.dimension]?.data.value,
        })),
        R.filter((e) => R.isTruthy(e.value)),
        R.map((e) =>
            buildQueryParameterFilter(e.key, e.condition, e.value as string)
        )
    );
}
