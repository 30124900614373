import {
    clearState,
    zustandStore,
    Write,
    getStateKeys,
} from 'store/zustand.helpers';
import { RequestParameters } from 'types/Common';
import { Dimension } from 'types/Dimensions';
import * as R from 'remeda';
import { Filter, Comparator } from 'types/Common';

export interface BaseDimensionValue {
    value: string;
}

export interface DropdownDimensionValue extends BaseDimensionValue {
    label: string;
}

export interface InputDimensionValue extends BaseDimensionValue {}

export interface DateDimensionValue extends BaseDimensionValue {}

export interface DataDimensionValue extends BaseDimensionValue {}

export type DimensionValue =
    | InputDimensionValue
    | DropdownDimensionValue
    | DateDimensionValue
    | DataDimensionValue;

export interface DimensionData {
    data: DimensionValue;
    requestParameters: RequestParameters;
}

interface DimensionValueState {
    [key: string]: DimensionData | undefined;
}

interface DimensionValueActions {
    setData: (
        dimension: string,
        data: DimensionValue,
        requestParameters: RequestParameters
    ) => void;

    /**
     * clears all values within the store
     */
    clearAllValues: () => void;

    /**
     * helper method that guarantees return value of dimension data.
     */
    getDimensionData: (dimension: string) => DimensionValue;

    /**
     * initialize provided dimensions with empty values
     */
    initDimensions: (dimensions: Dimension[]) => void;

    /**
     * Reset current dimension values
     */
    resetCurrentDimensions: () => void;

    /**
     * helper method that returns a list of filters of dimension id to dimension value.
     */
    getDimensionValueFilters: (dimensions: Dimension[]) => Filter[];
}

const initialState: DimensionValueState = {};

export const newDimensionData: (value?: string) => DimensionData = (
    value = ''
) => ({
    data: { value },
    requestParameters: {},
});

export const { store: dimensionValueStore, useStore: useDimensionValueStore } =
    zustandStore<DimensionValueState, DimensionValueActions>(
        'Dimension Value',
        initialState,
        (set, get) => ({
            setData: (dimension, data, requestParameters) =>
                set(() => ({ [dimension]: { data, requestParameters } })),
            clearAllValues: () => set((state) => clearState(state), true),
            getDimensionData: (dimension) => {
                const selectedDimension = get()[dimension];
                if (selectedDimension) {
                    return selectedDimension.data;
                } else {
                    return newDimensionData().data;
                }
            },
            getDimensionValueFilters: (dimensions) => {
                const store = get() as Write<
                    DimensionValueState,
                    DimensionValueActions
                >;
                const dimensionsValueFilters: Filter[] = [];

                dimensions.forEach((dimension) => {
                    dimensionsValueFilters.push({
                        key: dimension,
                        comparator: Comparator.EQUALS,
                        value: store.getDimensionData(dimension).value,
                    });
                });

                return dimensionsValueFilters;
            },
            initDimensions: (dimensions) => {
                set(() =>
                    R.flatMapToObj(dimensions, (e) => [[e, newDimensionData()]])
                );
            },
            resetCurrentDimensions: () => {
                const store = get() as Write<
                    DimensionValueState,
                    DimensionValueActions
                >;
                const dimensions = getStateKeys(store);
                store.initDimensions(dimensions);
            },
        })
    );
