import * as React from 'react';
import { Text, Card, Icon } from '@amzn/storm-ui';
import styled from 'styled-components';
import { Domain } from 'types/Domain';
import { Deck } from 'types/Deck';
import { getDecks } from 'retrievers/api';
import { AlertType, useAlertStore } from 'store/alert';
import { useToggle } from '@react-hookz/web';
import {
    DetailPopover,
    DetailPopoverViewer,
} from 'components/common/DetailPopover';

const DomainCardContainer = styled(Card)<{ isSelected: boolean }>`
    border: 1px solid ${({ theme }) => theme.palette.blue[100]};
    height: 100%;
    box-sizing: border-box;
    padding: 15px;
    background: ${(props) =>
        props.isSelected ? ({ theme }) => theme.palette.blue[50] : 'inherit'};
    text-align: center;
    align-items: center;
    & > div {
        justify-content: center;
        flex-direction: column;
        display: flex;
    }
`;

const DomainCardDataContainer = styled('div')`
    align-items: center;
`;

export interface DomainCardProps extends Omit<Domain, 'programId'> {
    id: number;
    handleDomainSelect: (id: number) => void;
    isSelected: boolean;
}

export const DomainCard: React.FunctionComponent<DomainCardProps> = (
    props: DomainCardProps
) => {
    const { name, description, id, handleDomainSelect, isSelected } = props;
    const [decks, setDecks] = React.useState<Deck[]>([]);
    const [decksLoading, setDecksLoading] = React.useState<boolean>(false);
    const [alertState] = useAlertStore((state) => [state]);
    const [isToggled, toggle] = useToggle(false);

    React.useEffect(() => {
        if (isToggled && decks.length === 0) {
            (async () => {
                try {
                    setDecksLoading(true);
                    const response = await getDecks(id.toString());
                    setDecks(response.data);
                } catch (e: any) {
                    alertState.setAlert(e.message, AlertType.ERROR, e.stack);
                }
                setDecksLoading(false);
            })();
        }
    }, [isToggled]);

    const DecksViewer: React.FunctionComponent = () => {
        return (
            <DetailPopoverViewer>
                {decks.map((deck, i) => (
                    <Text key={i} fontSize="mini">
                        {deck.name}
                    </Text>
                ))}
            </DetailPopoverViewer>
        );
    };

    return (
        <DomainCardContainer
            id={`domainCard-${id}`}
            data-testid={`domainCard-${id}`}
            onClick={() => handleDomainSelect(id)}
            isSelected={isSelected}
        >
            <DomainCardDataContainer>
                <Text
                    id={`domainCardName-${id}`}
                    data-testid={`domainCardName-${id}`}
                    type="h3"
                >
                    {name}
                </Text>
                <Text
                    data-testid={`domainCardDescription-${id}`}
                    type="p"
                    textColor="secondary"
                >
                    {description}
                </Text>
                <DetailPopover isOpen={isToggled} open={toggle} label="Decks">
                    {decksLoading ? (
                        <Icon type="spinner" style={{ opacity: '0.5' }} />
                    ) : (
                        <DecksViewer />
                    )}
                </DetailPopover>
            </DomainCardDataContainer>
        </DomainCardContainer>
    );
};
