// DO NOT RENAME TO .TS file breaks process.env

import * as R from 'remeda';
import { useLocation } from 'react-router-dom';
import React from 'react';
import { Comparator, RequestParameters, Filter } from 'types/Common';
import { DimensionTemplates } from 'types/Dimensions';

/**
 * Converts key, condition, value into format used by endpoints to filter getParameters
 */
export const buildQueryParameterFilter = (
    key: string,
    comparator: Comparator,
    value: string
): Filter => {
    return { key, comparator, value };
};

/**
 * This is quite a complicated type to figure out, as its super generic will fix later
 * TODO
 * @param map with key:value where key is dimension data key value and value is requestParameter Key
 * Example: {value: 'test', name: 'label'} with a map of {value: 'requestParameterValue', name: 'requestParameterName'}
 * will make an output of {requestParameterValue: 'test', requestParameterName: 'label'}
 */
export const buildRequestParameter = (
    map: any
): ((value: any) => RequestParameters) => {
    return (value: any) =>
        R.pipe(
            map,
            R.keys,
            R.filter((e) => R.keys(value).includes(e)),
            R.map((e) => ({ [map[e]]: value[e] })),
            R.mergeAll
        ) as RequestParameters;
};

export const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const APPENV = {
    mock_server: process.env.REACT_APP_MOCK_SERVER === 'true',
};

export const prettyPrintParameters = (parameters: object): string[] => {
    const output: string[] = [];
    Object.entries(parameters)
        .sort()
        .forEach(([key, value]) => {
            output.push(`${key}: ${value}`);
        });
    return output;
};

export const getLabelsFromDimensionTemplates = (
    id: string[],
    templates: DimensionTemplates[][]
): string[] => {
    const dimensions: DimensionTemplates[] = R.pipe(
        templates,
        R.flatten
    ) as any;
    return id.map(
        (e) => dimensions.find((d) => d.id === e)!.configuration.label
    );
};
