import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import { Credentials } from '@aws-sdk/types';

import { getJwtPayload, isExpired } from 'endpoint/jwt';
import { MidwayClientConfig, getToken } from 'endpoint/midway';
import { APPENV } from 'utils/app.utils';

const MIDWAY_HOSTNAME = 'midway-auth.amazon.com';

const cache: Map<string, Promise<Credentials>> = new Map();
let jwtCache: Promise<string> | undefined;

export async function getTokenFromCacheOrMidway(
    config: MidwayClientConfig
): Promise<string> {
    if (jwtCache) {
        const jwtPayload = getJwtPayload(await jwtCache);
        if (jwtPayload?.exp && !isExpired(jwtPayload.exp)) {
            return jwtCache;
        }
    }
    jwtCache = getToken(config);
    return jwtCache;
}

export async function getUsername(): Promise<string> {
    if (APPENV.mock_server) {
        return 'dev-user';
    }
    const token = await getTokenFromCacheOrMidway({
        clientId: window.location.host,
        redirectUri: window.location.href,
    });
    const payload = getJwtPayload(token);
    if (payload === null || payload?.sub === undefined) {
        throw Error('Could not extract username from token');
    }
    return payload.sub;
}

/**
 * @param region the region of the API
 * @param identityPoolId the ID for the IdentityPool to authenticate against
 */
export interface SignRequestConfig extends MidwayClientConfig {
    region: string;
    identityPoolId: string;
}

export async function getCredentialsFromCacheOrCognito(
    config: SignRequestConfig
): Promise<Credentials> {
    const cacheKey = JSON.stringify(config);
    const cacheCredentials = cache.get(cacheKey);
    const hasExpired = cacheCredentials?.then(
        ({ expiration }) => !expiration || expiration <= new Date()
    );
    if (cacheCredentials && !(await hasExpired)) {
        return cacheCredentials;
    }

    const credentials = fromCognitoIdentityPool({
        identityPoolId: config.identityPoolId,
        client: new CognitoIdentityClient({
            region: config.region,
        }),
        logins: {
            [MIDWAY_HOSTNAME]: async () => getTokenFromCacheOrMidway(config),
        },
    })();

    cache.set(cacheKey, credentials);

    return credentials;
}
