import { Divider } from '@amzn/storm-ui';
import { useIntervalEffect } from '@react-hookz/web';
import * as React from 'react';
import * as R from 'remeda';
import { ActionPanel } from 'components/home/ActionPanel';
import { DeckStatusViewer } from 'components/home/DeckStatusViewer';
import { DeckStatus, Status, Step } from '../types/Status';
import { getDeckStatuses } from '../retrievers/api';
import { AlertType, useAlertStore } from 'store/alert';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import styled from 'styled-components';
import { WidgetContainer } from 'containers/WidgetContainer';
import { differenceInSeconds } from 'date-fns';
import maxDates from 'date-fns/max';

const Widget = styled.div`
    @media only screen and (min-width: 500px) {
        display: none;
    }
`;

const Home = styled.div`
    @media only screen and (max-width: 500px) {
        display: none;
    }
`;

export const MyInsightsHelper = {
    getRefreshInterval: (
        reportStatuses: DeckStatus[] | undefined
    ): number | undefined => {
        const quickIntervalRefreshRateMS = 5000; // 5 seconds;
        const intervalRefreshRateMS = 300000; // 5 minutes;
        if (reportStatuses) {
            const mostRecentDate = R.pipe(
                reportStatuses,
                R.filter(
                    (e) =>
                        e.status === Status.IN_PROGRESS ||
                        (e.step === Step.ARTIFACTS_GENERATION &&
                            e.status === Status.SUCCESS)
                ),
                R.map((e) =>
                    e.updateTimestamp ? new Date(e.updateTimestamp) : null
                ),
                R.filter(R.isDate),
                maxDates
            );
            console.log(mostRecentDate);
            const differenceSeconds = Math.abs(
                differenceInSeconds(mostRecentDate, new Date())
            );
            if (Number.isNaN(differenceSeconds)) {
                return undefined;
            } else if (differenceSeconds <= 30)
                return quickIntervalRefreshRateMS;
            else return intervalRefreshRateMS;
        }
        return undefined;
    },
};

interface HomeContainerProps {}

export const HomeContainer: React.FunctionComponent<
    HomeContainerProps
> = () => {
    const [deckStatuses, setDeckStatuses] = React.useState<DeckStatus[]>([]);
    const [deckStatusesLoading, setDeckStatusesLoading] =
        React.useState<boolean>(true);
    const [alertState] = useAlertStore((state) => [state]);
    const [lastApiResponse, setLastApiResponse] = React.useState(new Date());

    React.useEffect(() => {
        (async () => {
            try {
                const response = await getDeckStatuses();
                setDeckStatuses(response.data);
            } catch (e: any) {
                alertState.setAlert(e.message, AlertType.ERROR, e.stack);
            }
            setDeckStatusesLoading(false);
        })();
    }, []);

    const intervalRefreshRateMS = React.useMemo(
        () => MyInsightsHelper.getRefreshInterval(deckStatuses),
        [lastApiResponse, deckStatusesLoading, deckStatuses]
    );

    useIntervalEffect(async () => {
        try {
            const response = await getDeckStatuses();
            setDeckStatuses(response.data);
            setLastApiResponse(new Date());
        } catch (e: any) {
            alertState.setAlert(e.message, AlertType.ERROR, e.stack);
        }
    }, intervalRefreshRateMS);

    return (
        <>
            <Widget>
                {deckStatusesLoading ? (
                    <LoadingSpinner />
                ) : (
                    <WidgetContainer deckStatuses={deckStatuses} />
                )}
            </Widget>
            <Home>
                <ActionPanel />
                <Divider />
                {deckStatusesLoading ? (
                    <LoadingSpinner />
                ) : (
                    <DeckStatusViewer deckStatuses={deckStatuses} />
                )}
            </Home>
        </>
    );
};
