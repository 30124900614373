import { useMountEffect } from '@react-hookz/web';
import { JsonVisualizationConfiguration, JsonVisualization } from './json';

export enum VisualizationTypes {
    NONE = 'none',
    JSON = 'json',
}

export interface BaseVisualizationConfiguration {
    type: VisualizationTypes;
}

export interface VisualizationComponentProps {
    label: string;
    input: any;
}

export type VisualizationConfiguration = JsonVisualizationConfiguration;

export const VisualizationComponentMap: {
    [key in VisualizationTypes]: React.FunctionComponent<any>;
} = {
    [VisualizationTypes.NONE]: () => null,
    [VisualizationTypes.JSON]: JsonVisualization,
};

export const ErrorVisualization: React.FunctionComponent = () => {
    useMountEffect(() => {
        console.warn('Invalid Visualization Source');
    });
    return null;
};
