import { zustandStore } from 'store/zustand.helpers';

export enum AlertType {
    INFO = 'info',
    WARNING = 'warning',
    ERROR = 'error',
    SUCCESS = 'success',
    FEATURE = 'feature-introduction',
}

export interface AlertState {
    message: string;
    type: AlertType;
    stack: string | undefined;
}

export interface AlertActions {
    setAlert: (message: string, type: AlertType, stack?: any) => void;
    resetAlert: () => void;
}

export const initialState: AlertState = {
    message: '',
    type: AlertType.INFO,
    stack: '',
};

export const { store: alertStore, useStore: useAlertStore } = zustandStore<
    AlertState,
    AlertActions
>('Alert', initialState, (set) => ({
    setAlert: (message: string, type: AlertType, stack = {}) =>
        set(() => ({
            message,
            type,
            stack,
        })),
    resetAlert: () => set(() => ({ ...initialState })),
}));
