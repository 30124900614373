import * as React from 'react';
import DatePicker, { DatePickerInput } from '@amzn/storm-ui-date-picker';
import { Popover, trapTabFocus, keyboardKeyNames } from '@amzn/storm-ui';
import { format } from 'date-fns';
import { EventListener } from 'utils/keyCodes';
import { LabelEndComponent } from './LabelEndComponent';

export interface DateComponentProps {
    dimension: string;
    label: string;
    value: string;
    startRange: string;
    disabled: boolean;
    setValue: (e: string) => void;
    dateFormat: string;
    optional?: boolean;
    tooltip?: string;
}

export const DateHelpers = {
    /**
     * converts local timezone to midnight UTC time.  This is needed because
     * e.g. new Date("2022-10-08") results in Fri Oct 07 2022 20:00:00 GMT-0400 (Eastern Daylight Time)
     * this function converts it back to Sat Oct 08 2022 00:00:00 GMT-0400 (Eastern Daylight Time)
     */
    getDateWithOffset: (date: Date): Date => {
        const timezoneOffset = date.getTimezoneOffset() * 60000;
        const adjustedDate = new Date(date.getTime() + timezoneOffset);
        return adjustedDate;
    },
};

export const DateComponent: React.FunctionComponent<DateComponentProps> = (
    props
) => {
    const {
        dimension,
        label,
        value,
        setValue,
        startRange,
        disabled,
        dateFormat,
        optional,
        tooltip,
    } = props;
    const [activeDatePicker, setActiveDatePicker] = React.useState(false);
    const [showFocus, setShowFocus] = React.useState(false);

    const [startRangeMinDate, setStartRangeMinDate] = React.useState<
        Date | undefined
    >(undefined);

    const handleTogglePopover = () => {
        setActiveDatePicker(!activeDatePicker);
    };

    const handleDateSelect = (newDate: Date) => {
        setValue(format(newDate, dateFormat));
        handleTogglePopover();
    };

    const handleKeyDownPopover = (event: any) => {
        keyDownPopover(event, popoverRef, handleTogglePopover);
    };

    const handleClosePopover = () => {
        setActiveDatePicker(false);
    };

    const keyDownPopover = (
        event: any,
        popoverRef: any,
        togglePopoverFunc: any
    ) => {
        const { key } = event;
        if (popoverRef && popoverRef.wrapperRef) {
            trapTabFocus(event, popoverRef.wrapperRef);
        }

        if (key === keyboardKeyNames.ESCAPE) {
            event.preventDefault();
            togglePopoverFunc(event);
        }
    };

    const handleShowFocus = (event: any) => {
        if (event && event.detail) {
            setShowFocus(false);
        } else {
            setShowFocus(true);
        }
    };

    const handleClick = (event: any) => {
        handleShowFocus(event);
        handleTogglePopover();
    };

    const anchorRef = React.useRef();
    const popoverRef = React.useRef();

    // these refs are mandatory
    const getPickerRef = (element: any) => {
        anchorRef.current = element;
    };
    const getPopoverRef = (element: any) => {
        popoverRef.current = element;
    };

    const handleKeyDownInput = (event: any) => {
        if (event.key === keyboardKeyNames.ESCAPE) {
            event.preventDefault();
            handleClosePopover();
        }
    };

    const labelEndProps = { tooltip, optional };

    React.useEffect(() => {
        const startRangeDate =
            startRange.length === 0
                ? undefined
                : DateHelpers.getDateWithOffset(new Date(startRange));
        setStartRangeMinDate(startRangeDate);
    }, [startRange]);

    return (
        <div>
            <Popover
                data-testid={`${dimension}-popover`}
                position="bottom"
                align="start"
                withBackdrop
                isOpen={activeDatePicker}
                anchorEl={anchorRef.current}
                onClose={handleTogglePopover}
                showFocus={showFocus}
                ref={getPopoverRef}
                attachCustomEvents={(element: any) => {
                    element.addEventListener(
                        EventListener.KEYDOWN,
                        handleKeyDownPopover
                    );
                }}
            >
                <DatePicker
                    onChange={handleDateSelect}
                    date={
                        value.length === 0
                            ? undefined
                            : DateHelpers.getDateWithOffset(new Date(value))
                    }
                    minDate={startRangeMinDate}
                />
            </Popover>
            <DatePickerInput
                data-testid={`${dimension}-date-picker`}
                id="date-picker"
                label={label}
                renderLabelEnd={<LabelEndComponent {...labelEndProps} />}
                readOnly
                value={value.length === 0 ? `Select ${label}` : value}
                onKeyDownPrefix={() => {}}
                onKeyUpPrefix={() => {}}
                disabled={disabled}
                prefixAriaLabel="select date"
                fullWidth
                inputRef={getPickerRef}
                onClick={handleClick}
                onKeyDown={handleKeyDownInput}
            />
        </div>
    );
};
