import { zustandStore } from 'store/zustand.helpers';
import { ApiRetriever } from 'types/retrievers';
import { CdbApiRetriever } from 'retrievers/cdbApi';

export interface AuthSettings {
    cognitoPoolId: string;
    apiEndpoint: string;
    region: string;
    adsApiEndpoint: string;
    retriever?: ApiRetriever;
}

export interface AuthState {
    username: string;
    settings: AuthSettings;
}

export interface AuthActions {
    setUsername: (username: string) => void;
    setSettings: (settings: AuthSettings) => void;
    resetState: () => void;
}

export const initialState: AuthState = {
    settings: {
        cognitoPoolId: '',
        apiEndpoint: '',
        region: '',
        adsApiEndpoint: '',
    },
    username: '',
};

export const { store: authStore, useStore: useAuthStore } = zustandStore<
    AuthState,
    AuthActions
>('Auth', initialState, (set: any) => ({
    setUsername: (username: string) =>
        set(() => ({
            username,
        })),
    setSettings: (settings: AuthSettings) =>
        set(() => ({
            settings: {
                cognitoPoolId: settings.cognitoPoolId,
                apiEndpoint: settings.apiEndpoint,
                region: settings.region,
                adsApiEndpoint: settings.adsApiEndpoint || '',
                retriever: settings.retriever || new CdbApiRetriever(),
            },
        })),
    resetState: () => set(() => ({ ...initialState })),
}));
