import * as React from 'react';
import { DeckStatus } from '../types/Status';
import styled from 'styled-components';
import { Text } from '@amzn/storm-ui';
import { DeckStatusWidgetCard } from 'components/widget/DeckStatusWidgetCard';

const DeckStatusWidgetContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
`;

const DeckStatusWidgetCardContainer = styled.div`
    list-style: none;
    box-sizing: border-box;
    width: 184px;
    height: 120px;
    margin-bottom: 12px;
`;

interface WidgetContainerProps {
    deckStatuses: DeckStatus[];
}

export const WidgetContainer: React.FunctionComponent<WidgetContainerProps> = (
    props: WidgetContainerProps
) => {
    const { deckStatuses } = props;
    return (
        <DeckStatusWidgetContainer>
            {deckStatuses.length > 0 ? (
                deckStatuses.map((deck, i) => {
                    const {
                        name,
                        description,
                        deckName,
                        status,
                        step,
                        updateTimestamp,
                        parameters,
                    } = deck;
                    return (
                        <DeckStatusWidgetCardContainer
                            key={i}
                            id={`deckStatusWidgetCard-${i}`}
                            data-testid={`deckStatusWidgetCard-${i}`}
                        >
                            <DeckStatusWidgetCard
                                id={i}
                                name={name}
                                description={description}
                                deckName={deckName}
                                status={status}
                                step={step}
                                updateTimestamp={updateTimestamp}
                                parameters={parameters}
                            />
                        </DeckStatusWidgetCardContainer>
                    );
                })
            ) : (
                <Text>Please expand the window to create a deck.</Text>
            )}
        </DeckStatusWidgetContainer>
    );
};
