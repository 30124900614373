import jwtDecode, { JwtPayload } from 'jwt-decode';

export function getJwtPayload(token: string): JwtPayload | null {
    try {
        return jwtDecode<JwtPayload>(token);
    } catch (err) {
        return null;
    }
}

export function isExpired(expiry: number): boolean {
    // expiry is in epoch seconds
    // https://datatracker.ietf.org/doc/html/rfc7519#section-4.1.4
    const now = Math.floor(Date.now() / 1000);
    return expiry < now;
}
