import React from 'react';
import { Input, InputFormGroupProps } from '@amzn/storm-ui';
import { LabelEndComponent } from './LabelEndComponent';

export enum InputComponentTypes {
    TEXT = 'text',
    NUMBER = 'number',
    PERCENT = 'percent',
}

const InputTypeMap: { [key in InputComponentTypes]: string } = {
    [InputComponentTypes.TEXT]: 'text',
    [InputComponentTypes.NUMBER]: 'number',
    [InputComponentTypes.PERCENT]: 'number',
};

export interface InputComponentProps {
    label: string;
    tooltip?: string;
    type: InputComponentTypes;
    dimension: string;
    disabled?: boolean;
    value: string;
    setValue: (e: string) => void;
    warningMessage?: string;
    optional?: boolean;
}

export const InputComponent: React.FunctionComponent<InputComponentProps> = (
    props
) => {
    const {
        disabled,
        value,
        setValue,
        dimension,
        tooltip,
        type,
        label,
        warningMessage,
        optional,
    } = props;

    const statusType =
        warningMessage && warningMessage.length > 0 ? 'warning' : undefined;
    const conditionalParameters = {} as InputFormGroupProps;
    const labelEndProps = { tooltip, optional };
    conditionalParameters.renderLabelEnd = (
        <LabelEndComponent {...labelEndProps} />
    );
    return (
        <Input
            {...conditionalParameters}
            id={dimension}
            data-testid={`${dimension}-input`}
            label={label}
            type={InputTypeMap[type]}
            disabled={disabled}
            statusType={statusType}
            message={warningMessage}
            value={value}
            fullWidth
            onChange={(e) => setValue(e.target.value)}
        />
    );
};
