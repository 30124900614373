import React, { useEffect, useMemo } from 'react';

import { DimensionConfiguration, DimensionTemplate } from 'types/Dimensions';

import * as R from 'remeda';

import 'react-json-view-lite/dist/index.css';
import { useAsync } from '@react-hookz/web';
import { useGetRequestParameters } from 'utils/dimension.hooks';
import {
    DimensionStatus,
    useDimensionStatusStore,
} from 'store/dimension.status';

import { DataSourceConfiguration, DataSourceGetterMap } from './datasources';
import {
    ErrorVisualization,
    VisualizationComponentMap,
    VisualizationConfiguration,
    VisualizationTypes,
} from './visualization/formatHandler';
import { ComponentLabel } from 'components/common/Styles';
import {
    ValueConfiguration,
    ValueHandlerMap,
    ValueTypes,
} from './value/formatHandler';
export interface DataDimensionConfiguration extends DimensionConfiguration {
    datasource: DataSourceConfiguration;
    visualization?: VisualizationConfiguration;
    value?: ValueConfiguration;
}

export interface DataDimensionProps extends DimensionTemplate {
    configuration: DataDimensionConfiguration;
}

export class DataDimensionHelpers {
    static processDimensionStatus(
        status: DimensionStatus,
        optional?: boolean
    ): DimensionStatus {
        if (optional) return DimensionStatus.SUCCESS;
        return status;
    }
}

export const DataDimension: React.FunctionComponent<DataDimensionProps> = (
    props
) => {
    const { optional, configuration, id, dependencies } = props;
    const [setStatus, dependenciesCheck] = useDimensionStatusStore((state) => [
        state.setStatus,
        state.checkStatusSuccess(dependencies),
    ]);

    const VisComponent =
        VisualizationComponentMap[
            configuration.visualization?.type ?? VisualizationTypes.NONE
        ] ?? ErrorVisualization;
    const getDataFunction = DataSourceGetterMap[configuration.datasource.type];
    const valueHandler =
        ValueHandlerMap[configuration.value?.type ?? ValueTypes.NONE];

    const resolvedDataRequirements = useGetRequestParameters(dependencies);

    // build requirements payload
    const dataPayload = useMemo(() => {
        const requirements = props.configuration.datasource.requirements;

        if (!dependenciesCheck) {
            return {
                status: 'waiting',
                message: 'dependencies not ready',
                state: R.pipe(
                    requirements,
                    R.map((r) => ({ [r]: undefined })),
                    R.mergeAll
                ) as any,
            };
        }
        const availableKeys = Object.keys(resolvedDataRequirements);
        const missingRequirements = R.pipe(
            requirements,
            R.map((r) => availableKeys.includes(r))
        ).includes(false);
        if (missingRequirements) {
            return {
                status: 'error',
                message: 'missing requirements',
                state: R.pipe(
                    requirements,
                    R.map((r) => ({ [r]: undefined })),
                    R.mergeAll
                ) as any,
            };
        } else {
            return {
                status: 'success',
                state: R.pick(resolvedDataRequirements, requirements),
            };
        }
    }, [resolvedDataRequirements]);

    const [dataState, dataActions] = useAsync(async () => {
        setStatus(
            id,
            DataDimensionHelpers.processDimensionStatus(
                DimensionStatus.LOADING,
                optional
            )
        );
        let data: any = {};
        try {
            data = await getDataFunction(
                props.configuration.datasource.settings,
                dataPayload.state
            );
        } catch (error) {
            setStatus(
                id,
                DataDimensionHelpers.processDimensionStatus(
                    DimensionStatus.ERROR,
                    optional
                )
            );
            throw error;
        }

        setStatus(
            id,
            DataDimensionHelpers.processDimensionStatus(
                DimensionStatus.SUCCESS,
                optional
            )
        );
        return data;
    });

    const label = props.configuration.label;

    useEffect(() => {
        if (dataPayload.status === 'success') {
            dataActions.execute();
        }
    }, Object.values(dataPayload.state));

    valueHandler(id, dataState.result, configuration.value!);

    if (dataState.status === 'success') {
        return (
            <VisComponent
                {...props.configuration.visualization}
                input={dataState.result}
                label={label}
            />
        );
    }

    if (dataState.status === 'error') {
        return (
            <div>
                <div>
                    <ComponentLabel>{label}</ComponentLabel>
                </div>
                <code>Failed to fetch data: {dataState.error?.message}</code>
            </div>
        );
    }
    return <></>;
};
