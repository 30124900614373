import React, { useMemo, useState, useEffect, useRef } from 'react';
import { useDebouncedEffect, useAsync } from '@react-hookz/web';
import * as R from 'remeda';
import { AlertType, useAlertStore } from 'store/alert';
import {
    DropdownDimensionValue,
    useDimensionValueStore,
} from 'store/dimension.value';
import {
    Dimension,
    DimensionConfiguration,
    DimensionTemplate,
} from 'types/Dimensions';
import {
    InputParameterFilter,
    Comparator,
    Filter,
    OrderBy,
} from 'types/Common';
import {
    clearDimensionValueOnDependencyChange,
    useGetDimensionDisable,
    buildQueryParamFiltersFromDimensions,
    useSetDimensionValue,
    useUpdateStatusOnChange,
} from 'utils/dimension.hooks';
import {
    DropdownComponent,
    DropdownComponentProps,
    DropdownItemData,
} from 'components/common/Dropdown';
import {
    DimensionStatus,
    useDimensionStatusStore,
} from 'store/dimension.status';
import {
    buildRequestParameter,
    buildQueryParameterFilter,
} from 'utils/app.utils';
import { getDropdownValues } from 'retrievers/api';
import { useCtiStore } from 'store/cti';
import { buildMissingTextWithSimTemplate } from 'utils/cti.utils';

export interface ItemMapper {
    label: string;
    value: string;
    [key: string]: string;
}

export interface DropdownDimensionProps extends DimensionTemplate {
    configuration: DropdownDimensionConfiguration;
}

export interface DropdownDimensionConfiguration extends DimensionConfiguration {
    inputParameterFilters?: InputParameterFilter[];
    orderBy?: OrderBy[];
    itemMapper: ItemMapper;
    inputSource: string;
    missingText?: string;
}

export class DropdownDimensionHelpers {
    static processItemMapper(
        data: Record<string, string>[],
        itemMapper: ItemMapper
    ): DropdownItemData[] {
        const itemMapPairs = Object.entries(itemMapper);
        const itemMapBuilder = (d: Record<string, string>): DropdownItemData =>
            R.pipe(
                itemMapPairs,
                R.map(([k, v]) => ({ [k]: d[v] })),
                R.mergeAll
            ) as any;
        return R.pipe(data, R.map(itemMapBuilder));
    }

    static getDropdownFilters = (
        inputParameterFilters: InputParameterFilter[] | undefined,
        searchFilter?: Filter
    ) => {
        const filters: Filter[] = [];
        if (inputParameterFilters && inputParameterFilters.length > 0) {
            filters.push(
                ...buildQueryParamFiltersFromDimensions(inputParameterFilters)
            );
        }
        if (searchFilter) {
            filters.push(searchFilter);
        }
        return filters.length > 0 ? filters : undefined;
    };

    static buildUrl = (queryParameters: Array<string | undefined>) => {
        return R.pipe(queryParameters, R.filter(R.isDefined), (x) =>
            x.join('&')
        );
    };

    static buildRequestParameter = (
        id: Dimension,
        v: DropdownDimensionValue
    ) => ({
        [`${id}_id`]: v.value,
        [`${id}_name`]: v.label,
    });

    static defaultRequestParameterMapping = (dimension: string) => ({
        value: `${dimension}_id`,
        label: `${dimension}_name`,
    });

    static processDimensionStatus = (
        { value }: DropdownDimensionValue,
        optional: boolean
    ) => {
        if (optional) return DimensionStatus.SUCCESS;
        if (value.length === 0) {
            return DimensionStatus.EMPTY;
        }
        return DimensionStatus.SUCCESS;
    };
}

export const DropdownDimension: React.FunctionComponent<
    DropdownDimensionProps
> = (props) => {
    const {
        dependencies,
        id,
        configuration,
        optional = false,
        requestParameterMap,
    } = props;
    const {
        inputParameterFilters = [],
        itemMapper,
        label,
        tooltip,
        inputSource,
        missingText,
    } = configuration;

    const dependencyValues = useDimensionValueStore((state) =>
        dependencies.map((d) => state[d]?.data)
    );

    const lastApiStartTime = useRef(0);

    const dependencyAllSuccess = useDimensionStatusStore((state) =>
        state.checkStatusSuccess(dependencies)
    );

    const [showItemsWhileLoading, setShowItemsWhileLoading] = useState(false);

    const [setAlert] = useAlertStore((state) => [state.setAlert]);
    const [ctiState] = useCtiStore((state) => [state]);

    const [filter, setFilter] = useState('');
    const [pageIndex, setPageIndex] = useState(0);
    const [items, setItems] = useState<DropdownItemData[]>([]);
    const maxResultsResponse = useRef(Number.MAX_VALUE);

    // build query parameters
    const queryParameter = useMemo(
        () => `${Object.values(itemMapper).join(',')}`,
        []
    );

    const dropdownFilterQueryParameter = useMemo(
        () =>
            DropdownDimensionHelpers.getDropdownFilters(
                inputParameterFilters,
                filter.length > 0
                    ? buildQueryParameterFilter(
                          itemMapper.label,
                          Comparator.ILIKE,
                          `%${filter}%`
                      )
                    : undefined
            ),
        [filter, ...dependencyValues]
    );

    const rpMap = useMemo(
        () =>
            requestParameterMap ||
            DropdownDimensionHelpers.defaultRequestParameterMapping(id),
        []
    );

    const [inputRequestState, inputRequestAction] = useAsync(async () => {
        if (!dependencyAllSuccess) {
            return;
        }
        try {
            const execution = new Date().valueOf();
            const { data, maxResults } = await getDropdownValues(
                {
                    datasetName: inputSource,
                    columnNames: queryParameter,
                    additionalFilters: dropdownFilterQueryParameter,
                    orderBy: configuration.orderBy || [],
                },
                pageIndex
            );
            if (execution < lastApiStartTime.current) {
                return null;
            }
            maxResultsResponse.current = maxResults;
            const fetchData = DropdownDimensionHelpers.processItemMapper(
                data,
                itemMapper
            );
            if (pageIndex === 0) {
                setItems(fetchData);
            } else {
                setItems([...items, ...fetchData]);
            }
            setShowItemsWhileLoading(false);
            lastApiStartTime.current = execution;
        } catch (err: any) {
            setAlert(err.message, AlertType.ERROR, err.stack);
        }
    });

    useDebouncedEffect(
        async () => {
            await inputRequestAction.execute();
        },
        [pageIndex, dropdownFilterQueryParameter],
        300
    );

    // clears page index when dependency values change
    useEffect(() => {
        setItems([]);
        setPageIndex(0);
    }, [dropdownFilterQueryParameter]);

    // clears page index if filter changes
    useEffect(() => {
        setPageIndex(0);
        setItems([]);
    }, [filter]);

    const disabled = useGetDimensionDisable(dependencies);

    clearDimensionValueOnDependencyChange(id, dependencies);

    const [dimensionData, setDimensionValue] =
        useSetDimensionValue<DropdownDimensionValue>(
            id,
            buildRequestParameter(rpMap)
        );

    // set status
    useUpdateStatusOnChange(id, (value: DropdownDimensionValue) =>
        DropdownDimensionHelpers.processDimensionStatus(value, optional)
    );

    const missingTextWithSimTemplate: string | undefined = useMemo(() => {
        const templateId = ctiState.cti?.simTTemplateId;
        return buildMissingTextWithSimTemplate(label, templateId);
    }, [ctiState.cti]);

    const dropdownProps: DropdownComponentProps = {
        dimension: id,
        label,
        tooltip,
        items,
        search: filter,
        onChange: (e) => {
            const value: DropdownDimensionValue = items.find(
                (item) => item.value === e
            ) || {
                value: e,
                label: e,
            };
            setDimensionValue(value);
        },
        selectedValue: dimensionData.value,
        setSearch: (e) => {
            inputRequestState.status = 'loading';
            setFilter(e);
        },
        onEndScroll: async () => {
            if (items.length < maxResultsResponse.current) {
                setShowItemsWhileLoading(true);
                inputRequestState.status = 'loading';
                setPageIndex(pageIndex + 1);
            }
        },
        loading: inputRequestState.status === 'loading',
        showItemsWhileLoading,
        disabled,
        optional,
        missingText: missingText ?? missingTextWithSimTemplate,
    };

    return <DropdownComponent {...dropdownProps} />;
};

export default DropdownDimension;
