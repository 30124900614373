import React, { useMemo, useState } from 'react';
import {
    DropdownComponent,
    DropdownComponentProps,
    DropdownItemData,
} from 'components/common/Dropdown';
import { DimensionConfiguration, DimensionTemplate } from 'types/Dimensions';
import {
    clearDimensionValueOnDependencyChange,
    useGetDimensionDisable,
    useSetDimensionValue,
    useUpdateStatusOnChange,
} from 'utils/dimension.hooks';
import { DropdownDimensionValue } from 'store/dimension.value';
import { DimensionStatus } from 'store/dimension.status';
import { buildRequestParameter } from 'utils/app.utils';
import * as R from 'remeda';

export interface StaticDropdownDimensionConfiguration
    extends DimensionConfiguration {
    items: DropdownItemData[];
    showSearchInput?: boolean;
    additionalItemAttributes?: string[];
}

export interface StaticDropdownDimensionProps extends DimensionTemplate {
    configuration: StaticDropdownDimensionConfiguration;
}

export const StaticDropdownDimension: React.FunctionComponent<
    StaticDropdownDimensionProps
> = (props) => {
    const [filter, setFilter] = useState('');
    const { id, dependencies, configuration, optional, requestParameterMap } =
        props;
    const {
        label,
        tooltip,
        items,
        showSearchInput,
        additionalItemAttributes = [],
    } = configuration;

    const rpMap = useMemo(() => {
        const attributes = R.pipe(
            additionalItemAttributes,
            R.map((e) => ({ [e]: e })),
            R.mergeAll
        );
        return (
            requestParameterMap || {
                value: `${id}_id`,
                label: `${id}_name`,
                ...attributes,
            }
        );
    }, []);

    const disabled = useGetDimensionDisable(dependencies);
    const [dimensionData, setDimensionData] =
        useSetDimensionValue<DropdownDimensionValue>(
            id,
            buildRequestParameter(rpMap)
        );

    useUpdateStatusOnChange<DropdownDimensionValue>(id, ({ value }) => {
        if (optional) return DimensionStatus.SUCCESS;
        if (value.length === 0) {
            return DimensionStatus.EMPTY;
        }
        return DimensionStatus.SUCCESS;
    });

    clearDimensionValueOnDependencyChange(id, dependencies);

    const filteredItems = useMemo(
        () =>
            filter.length > 0
                ? items.filter(
                      ({ label, value }) =>
                          label?.toLowerCase().includes(filter.toLowerCase()) ||
                          value?.toLowerCase().includes(filter.toLowerCase())
                  )
                : items,
        [filter]
    );

    const dropdownProps: DropdownComponentProps = {
        dimension: id,
        tooltip,
        label,
        items: filteredItems,
        disabled,
        search: filter,
        setSearch: setFilter,
        selectedValue: dimensionData.value,
        showSearchInput,
        onChange: (v) => {
            setDimensionData({
                ...items.find((i) => i.value === v),
            } as any);
        },
        optional,
    };
    return <DropdownComponent {...dropdownProps} />;
};
