import * as React from 'react';
import { Button, Icon } from '@amzn/storm-ui';
import { circleQuestion } from '@amzn/storm-ui-icons';

import { HelpModal } from 'containers/HelpModal';

interface HelpComponentProps {
    details?: string;
}

export const HelpComponent: React.FunctionComponent<HelpComponentProps> = (
    props: HelpComponentProps
) => {
    const { details } = props;
    const [isModalOpen, setIsModelOpen] = React.useState(false);

    const toggleModal = (): void => {
        setIsModelOpen(!isModalOpen);
    };

    return (
        <div data-testid="helpComponent">
            <HelpModal
                isModalOpen={isModalOpen}
                toggleModal={toggleModal}
                details={details}
            />
            <Button data-testid="helpButton" transparent onClick={toggleModal}>
                <Icon type={circleQuestion} size="2x" />
            </Button>
        </div>
    );
};
