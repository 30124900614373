import AdvertisingApiClient from 'advertising-api-javascript-client';
import { authStore } from 'store/auth';
import { adsApiClientParam } from '../config/adsApi';
import {
    generatePathWithQuery,
    generateUrlWithProtocol,
} from '../utils/api.utils';
import { GetParams, PostParams } from 'types/api';

export const errorWrapper = async (api: Promise<any>): Promise<any> => {
    try {
        const response = await api;
        return response.data;
    } catch (e: any) {
        let message = e.message;
        if (e.response && e.response.data && e.response.data.message) {
            message = e.response.data.message;
        }
        throw new Error(message);
    }
};

const getClient = (endpoint: string, requesterAlias: string): Client => {
    const advertisingApiClient = new AdvertisingApiClient(
        adsApiClientParam(endpoint, requesterAlias)
    );
    return advertisingApiClient;
};

export const apiGet = async (params: GetParams): Promise<any> => {
    const { path, searchParams } = params;

    const {
        settings: { adsApiEndpoint },
        username,
    } = authStore.getState();
    const adsApiClient = getClient(
        generateUrlWithProtocol(adsApiEndpoint!),
        username
    );

    const response = adsApiClient.makeRequest<null, any>({
        method: 'GET',
        path: generatePathWithQuery(path, searchParams),
        additionalHeaders: {
            'x-amz-request-csrf-token': 'true',
        },
    });
    return errorWrapper(response);
};

export const apiPost = async (params: PostParams): Promise<any> => {
    const { path, searchParams, body } = params;
    const {
        settings: { adsApiEndpoint },
        username,
    } = authStore.getState();
    const adsApiClient = getClient(
        generateUrlWithProtocol(adsApiEndpoint!),
        username
    );

    const request = adsApiClient.makeRequest<any, any>({
        method: 'POST',
        path: generatePathWithQuery(path, searchParams),
        data: body,
        additionalHeaders: {
            'x-amz-request-csrf-token': 'true',
        },
    });
    return errorWrapper(request);
};
