import * as React from 'react';
import { Button, Modal, TextButton, Text } from '@amzn/storm-ui';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { sortDomains } from 'utils/domain.utils';
import { DomainCard } from 'components/home/DomainCard';
import { Domain } from 'types/Domain';
import { Program } from 'types/Program';
import { ProgramDropdown } from 'components/home/ProgramDropdown';
import { ALL_DOMAINS_PROGRAM } from 'components/home/ProgramDropdown';

const CancelButton = styled(TextButton)`
    margin-right: ${({ theme }) => theme.spacing.base};
`;

const DomainViewerContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

const DomainModalContentContainer = styled.div`
    width: 75vw;
`;

const DomainCardContainer = styled.div`
    list-style: none;
    flex: 0 0 30%;
    box-sizing: border-box;
    padding: 10px;
`;

export interface DomainModalProps {
    isModalOpen: boolean;
    toggleModal: () => void;
    domains: Domain[];
    programs: Program[];
    activeProgram: Program;
    setProgram: (program: Program) => void;
}

// IDs from Database will never be negative
export const NON_SELECTED_DOMAIN = -1;

export const DomainModal: React.FunctionComponent<DomainModalProps> = (
    props: DomainModalProps
) => {
    const [selectedDomainId, setSelectedDomainId] =
        React.useState<number>(NON_SELECTED_DOMAIN);

    const {
        isModalOpen,
        toggleModal,
        domains,
        programs,
        activeProgram,
        setProgram,
    } = props;
    const buttonRef = React.useRef();
    const navigate = useNavigate();
    const Footer = () => (
        <>
            <CancelButton onClick={handleClose} data-testid="domainCancel">
                Cancel
            </CancelButton>
            <Button
                onClick={handleSelect}
                primary
                disabled={selectedDomainId === NON_SELECTED_DOMAIN}
                data-testid="domainSelect"
            >
                Continue
            </Button>
        </>
    );

    const handleDomainSelect = (id: number): void => {
        if (selectedDomainId !== id) {
            setSelectedDomainId(id);
        }
    };

    const handleClose = (): void => {
        setSelectedDomainId(NON_SELECTED_DOMAIN);
        setProgram(ALL_DOMAINS_PROGRAM);
        toggleModal();
    };

    const handleSelect = (): void => {
        if (selectedDomainId !== NON_SELECTED_DOMAIN) {
            toggleModal();
            navigate(`domains/${selectedDomainId}/deck`);
        }
    };

    const activeDomains =
        activeProgram.id === ALL_DOMAINS_PROGRAM.id
            ? domains
            : domains.filter((domain) => domain.programId === activeProgram.id);

    return (
        <Modal
            id="domainSelection"
            data-testid="domainSelection"
            isOpen={isModalOpen}
            header="Select a Domain"
            footer={<Footer />}
            onClose={handleClose}
            padding="small"
            // The trigger element ref for Modal
            // Modal will use this ref to restore focus when closing
            toggleEl={buttonRef.current}
        >
            <DomainModalContentContainer>
                <ProgramDropdown
                    programs={programs}
                    program={activeProgram}
                    setProgram={setProgram}
                />
                <DomainViewerContainer>
                    {activeDomains.length > 0 ? (
                        sortDomains(activeDomains).map(
                            ({ name, description, id }) => (
                                <DomainCardContainer key={id}>
                                    <DomainCard
                                        name={name}
                                        description={description}
                                        id={id}
                                        isSelected={selectedDomainId === id}
                                        handleDomainSelect={handleDomainSelect}
                                    />
                                </DomainCardContainer>
                            )
                        )
                    ) : (
                        <Text>No Domains</Text>
                    )}
                </DomainViewerContainer>
            </DomainModalContentContainer>
        </Modal>
    );
};
