export const ESCAPE_EVENT = {
    key: 'Escape',
    code: 'Escape',
    keyCode: 27,
    charCode: 27,
};

export enum EventListener {
    KEYDOWN = 'keydown',
}
