import * as React from 'react';
import { Icon, Text } from '@amzn/storm-ui';

export const LoadingSpinner: React.FunctionComponent = () => {
    return (
        <div
            data-testid="loading_spinner"
            role="alert"
            aria-live="assertive"
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: 75,
            }}
        >
            <Icon type="spinner" size="3x" style={{ opacity: '0.5' }} />
            <Text type="h3" textColor="secondary" style={{ marginTop: '1rem' }}>
                Loading
            </Text>
        </div>
    );
};
