import { InputComponentTypes } from 'components/common/Input';
import { DataDimensionConfiguration } from 'components/dimensions/DataDimension';
import { DataSource } from 'components/dimensions/DataDimension/datasources';
import { VisualizationTypes } from 'components/dimensions/DataDimension/visualization/formatHandler';
import { DropdownDimensionConfiguration } from 'components/dimensions/DropdownDimension';
import { Comparator } from 'types/Common';
import { DimensionTemplates, DimensionTypes } from 'types/Dimensions';

export const dropdownTest: DimensionTemplates[][] = [
    [
        {
            id: 'locale',
            type: DimensionTypes.DROPDOWN,
            dependencies: [],
            requestParameterMap: {
                label: 'output_1',
                value: 'output_2',
                water: 'output_3',
            },
            configuration: {
                itemMapper: {
                    value: 'column1',
                    label: 'column2',
                    water: 'column1',
                },
                inputSource: 'test_parameters',
                label: 'Marketplace',
                items: [
                    {
                        value: 'us',
                        label: 'us',
                    },
                ],
            } as DropdownDimensionConfiguration,
        },
    ],
];

export const BgfCorePitchDimensionsTemplate: DimensionTemplates[][] = [
    [
        {
            id: 'name',
            type: DimensionTypes.INPUT,
            dependencies: [],
            configuration: {
                type: InputComponentTypes.TEXT,
                label: 'Name',
            },
        },
        {
            id: 'description',
            type: DimensionTypes.INPUT,
            dependencies: [],
            configuration: {
                type: InputComponentTypes.TEXT,
                label: 'Description',
            },
        },
    ],
    [
        {
            id: 'locale',
            type: DimensionTypes.STATIC_DROPDOWN,
            dependencies: [],
            configuration: {
                label: 'Marketplace',
                items: [
                    {
                        value: 'us',
                        label: 'us',
                    },
                ],
            },
        },
        {
            id: 'advertiser',
            type: DimensionTypes.STATIC_DROPDOWN,
            dependencies: ['locale'],
            configuration: {
                label: 'Advertiser',
                items: [
                    {
                        label: 'apple',
                        value: '1234556',
                    },
                    {
                        label: 'amazon',
                        value: '43214',
                    },
                ],
            },
        },
        {
            id: 'brand',
            type: DimensionTypes.STATIC_DROPDOWN,
            dependencies: ['advertiser', 'locale'],
            configuration: {
                label: 'Brand',
                items: [
                    {
                        label: 'brand1',
                        value: '1234556',
                    },
                    {
                        label: 'brand2',
                        value: '1234556',
                    },
                ],
            },
        },
        {
            id: 'time_period',
            type: DimensionTypes.STATIC_DROPDOWN,
            dependencies: [],
            configuration: {
                label: 'Time Period',
                items: [
                    {
                        label: '2022-Q3',
                        value: '2022-Q3',
                    },
                    {
                        label: '2022-Q2',
                        value: '2022-Q2',
                    },
                    {
                        label: '2022-Q1',
                        value: '2022-Q1',
                    },
                    {
                        label: '2021-Q3',
                        value: '2021-Q3',
                    },
                    {
                        label: '2021-Q2',
                        value: '2021-Q2',
                    },
                    {
                        label: '2021-Q1',
                        value: '2021-Q1',
                    },
                ],
            },
            requestParameterMap: {
                value: 'yearquarter',
            },
        },
    ],
    [
        {
            id: 'bn_1',
            type: DimensionTypes.STATIC_DROPDOWN,
            dependencies: ['locale', 'advertiser', 'brand'],
            configuration: {
                label: 'Category',
                items: [
                    {
                        label: 'foobar',
                        value: '123',
                    },
                    {
                        label: 'batman',
                        value: '123',
                    },
                ],
            },
        },
        {
            id: 'bn_2',
            type: DimensionTypes.STATIC_DROPDOWN,
            dependencies: ['bn_1'],
            configuration: {
                label: 'Subcategory',
                items: [
                    {
                        label: 'cheese',
                        value: '123',
                    },
                    {
                        label: 'cottage',
                        value: '123',
                    },
                ],
            },
        },
        {
            id: 'bn_3',
            type: DimensionTypes.STATIC_DROPDOWN,
            dependencies: ['bn_2'],
            configuration: {
                label: 'Tertiary Category',
                items: [
                    {
                        label: 'apple',
                        value: '1234556',
                    },
                ],
            },
        },
        {
            id: 'growth_rate',
            type: DimensionTypes.INPUT,
            dependencies: [],
            configuration: {
                type: InputComponentTypes.NUMBER,
                label: 'Growth rate %',
            },
        },
    ],
];

export const dataDimensionTest: DimensionTemplates[][] = [
    [
        {
            id: 'locale',
            type: DimensionTypes.DROPDOWN,
            dependencies: [],
            requestParameterMap: {
                label: 'output_1',
                value: 'output_2',
                water: 'output_3',
            },
            configuration: {
                itemMapper: {
                    value: 'column1',
                    label: 'column2',
                    water: 'column1',
                },
                inputSource: 'test_parameters',
                label: 'Marketplace',
                items: [
                    {
                        value: 'us',
                        label: 'us',
                    },
                ],
            } as DropdownDimensionConfiguration,
        },
        {
            id: 'segment_body',
            type: DimensionTypes.DATA,
            dependencies: ['locale'],
            configuration: {
                label: 'test',
                datasource: {
                    type: DataSource.CDB_INPUT_TABLE,
                    settings: {
                        tableName: 'some_table_name',
                        columns: ['a'],
                        filters: [
                            {
                                column: 'column_a',
                                comparator: Comparator.EQUALS,
                                requestParameterKey: 'output_1',
                            },
                        ],
                    },
                    requirements: ['output_1'],
                },
                visualization: {
                    settings: {
                        jsonPath: '$.[0].segment_definition',
                        parseJsonPathValue: true,
                    },
                    type: VisualizationTypes.JSON,
                },
            } as DataDimensionConfiguration,
        },
    ],
];
