import * as API from 'types/api';

import { createDate } from '../utils/deckStatus.utils';

import { AuthSettings, authStore } from 'store/auth';
import { ApiRetriever, DateRange, Pagination } from 'types/retrievers';

const getRetriever = (): ApiRetriever => {
    const settings: AuthSettings = authStore.getState().settings;
    if (settings.retriever) {
        return settings.retriever;
    }
    throw new Error(
        `Retriever cannot be determined based on host URL ${window.location.hostname}`
    );
};

export const getDomains = async (
    body: API.GetDomainsRequest = {},
    pageToken: number = 0,
    pageSize: number = 50
): Promise<API.GetDomainsResponse> => {
    const retriever: ApiRetriever = getRetriever();
    const pagination: Pagination = { pageSize, pageToken };
    return retriever.getDomains(body, pagination);
};

export const getDomain = async (id: string): Promise<API.GetDomainResponse> => {
    const retriever: ApiRetriever = getRetriever();
    return retriever.getDomain(id);
};

export const getDecks = async (
    id: string,
    body: API.GetDecksRequest = {},
    pageToken: number = 0,
    pageSize: number = 50
): Promise<API.GetDecksResponse> => {
    const retriever: ApiRetriever = getRetriever();
    const pagination: Pagination = { pageSize, pageToken };
    return retriever.getDecks(id, body, pagination);
};

export const getDropdownValues = async (
    body: API.GetDropdownValuesRequest,
    pageToken: number = 0,
    pageSize: number = 50
): Promise<API.GetDropdownValuesResponse> => {
    const retriever: ApiRetriever = getRetriever();
    const pagination: Pagination = { pageSize, pageToken };
    return retriever.getDropdownValues(body, pagination);
};

export const createDownload = async (
    deckId: number,
    executionId: string
): Promise<API.CreateDownloadResponse> => {
    const retriever: ApiRetriever = getRetriever();
    return retriever.createDownload(deckId, executionId);
};

export const getDeckStatuses = async (
    body: API.GetDeckStatusRequest = {},
    pageToken: number = 0,
    pageSize: number = 50,
    startDate: Date = createDate(-180, 0, 0),
    endDate: Date = createDate()
): Promise<API.GetDeckStatusResponse> => {
    const retriever: ApiRetriever = getRetriever();
    const dateRange: DateRange = { startDate, endDate };
    const pagination: Pagination = { pageSize, pageToken };
    return retriever.getDeckStatuses(body, pagination, dateRange);
};

export const createDeck = async (
    request: API.CreateDeckRequest
): Promise<API.CreateDeckResponse> => {
    const retriever: ApiRetriever = getRetriever();
    return retriever.createDeck(request);
};

export const getPrograms = async (
    body: API.GetProgramsRequest = {},
    pageToken: number = 0,
    pageSize: number = 50
): Promise<API.GetProgramsResponse> => {
    const retriever: ApiRetriever = getRetriever();
    const pagination: Pagination = { pageSize, pageToken };
    return retriever.getPrograms(body, pagination);
};
