import useCreate, { UseBoundStore } from 'zustand';
import create, { StateCreator, StoreApi } from 'zustand/vanilla';
import { devtools, combine } from 'zustand/middleware';
import * as R from 'remeda';

export type Write<T extends object, U extends object> = Omit<T, keyof U> & U;
export type Store<S extends object, A extends object> = StoreApi<Write<S, A>>;

export function zustandStore<S extends object, A extends object>(
    name: string,
    initialState: S,
    actions: StateCreator<S, [], [], A>
): {
    store: Store<S, A>;
    useStore: UseBoundStore<Store<S, A>>;
} {
    const combined = combine(initialState, actions as any);
    const store: any = create(devtools(combined as any, { name }));
    const useStore = useCreate(store);
    return {
        store,
        useStore,
    };
}

export function clearState(state: any) {
    return R.pipe(state, R.pickBy(R.isFunction));
}

export function getStateKeys(state: any) {
    return R.pipe(
        state,
        Object.keys,
        R.filter((e) => !R.isFunction(state[e]))
    );
}
