import {
    clearState,
    getStateKeys,
    Write,
    zustandStore,
} from 'store/zustand.helpers';
import { Dimension } from 'types/Dimensions';
import * as R from 'remeda';

export enum DimensionStatus {
    INVALID = 'invalid',
    SUCCESS = 'success',
    EMPTY = 'empty',
    LOADING = 'loading',
    ERROR = 'error',
}

interface DimensionStatusState {
    [key: string]: DimensionStatus;
}

interface DimensionStatusActions {
    setStatus: (dimension: string, status: DimensionStatus) => void;
    clearStatus: () => void;
    initDimensions: (dimensions: Dimension[]) => void;
    checkAllStatusSuccess: () => boolean;
    checkStatusSuccess: (dimensions: Dimension[]) => boolean;
    resetCurrentDimensions: () => void;
    getAllNonSuccessStatus: () => string[];
}

const initialState: DimensionStatusState = {};

export const {
    store: dimensionStatusStore,
    useStore: useDimensionStatusStore,
} = zustandStore<DimensionStatusState, DimensionStatusActions>(
    'Dimension Status',
    initialState,
    (set, get) => ({
        setStatus: (dimension, status) =>
            set(() => ({
                [dimension]: status,
            })),
        clearStatus: () => set((state) => clearState(state), true),
        initDimensions: (dimensions) =>
            set(() =>
                R.flatMapToObj(dimensions, (e) => [[e, DimensionStatus.EMPTY]])
            ),
        checkAllStatusSuccess: () => {
            return (
                R.pipe(
                    get(),
                    Object.values,
                    R.filter(R.isString),
                    R.countBy((e) => e !== DimensionStatus.SUCCESS)
                ) === 0
            );
        },
        checkStatusSuccess: (dimensions) => {
            return (
                R.pipe(
                    R.pick(get(), dimensions),
                    R.values,
                    R.countBy((e) => e !== DimensionStatus.SUCCESS)
                ) === 0
            );
        },
        resetCurrentDimensions: () => {
            const store = get() as Write<
                DimensionStatusState,
                DimensionStatusActions
            >;
            const dimensions = getStateKeys(store);
            store.initDimensions(dimensions);
        },
        getAllNonSuccessStatus: () => {
            return R.pipe(
                get(),
                R.toPairs,
                R.filter(
                    ([, val]) =>
                        val !== DimensionStatus.SUCCESS && R.isString(val)
                ),
                R.map(([key]) => key)
            );
        },
    })
);
