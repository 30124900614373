import * as React from 'react';
import { Modal, Divider, Text } from '@amzn/storm-ui';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

const ContentDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 50vw;
`;

const MarkdownDiv = styled.div`
    padding: 0 100px;
    display: flex;
    hyphens: auto;
`;

interface HelpModalProps {
    isModalOpen: boolean;
    toggleModal: () => void;
    details?: string;
}

export const HelpModal: React.FunctionComponent<HelpModalProps> = (
    props: HelpModalProps
) => {
    const { isModalOpen, toggleModal, details } = props;
    const buttonRef = React.useRef();

    const defaultDetailsList = [
        '[Contact us](https://t.corp.amazon.com/create/templates/4977f75f-0898-427c-a041-5e78fa14bcd8)',
        '[Request a feature and submit feedback](https://t.corp.amazon.com/create/templates/1c2eb066-f8ba-4a05-a3c9-e8b82c56edfd)',
    ];
    const defaultDetails = defaultDetailsList.join('  \n');

    return (
        <Modal
            header="Need Help?"
            isOpen={isModalOpen}
            onClose={toggleModal}
            toggleEl={buttonRef.current}
            padding="base"
            data-testid="helpModal"
        >
            <ContentDiv>
                {details && (
                    <MarkdownDiv data-testid="additionalDetails">
                        <Text>
                            <ReactMarkdown linkTarget="_blank">
                                {details}
                            </ReactMarkdown>
                        </Text>
                    </MarkdownDiv>
                )}
                {details && <Divider />}
                <MarkdownDiv
                    data-testid="defaultDetails"
                    style={{
                        justifyContent:
                            details === undefined ? 'center' : 'left',
                    }}
                >
                    <Text>
                        <ReactMarkdown linkTarget="_blank">
                            {defaultDetails}
                        </ReactMarkdown>
                    </Text>
                </MarkdownDiv>
            </ContentDiv>
        </Modal>
    );
};
