import * as React from 'react';
import { Status, Step } from 'types/Status';
import { Text } from '@amzn/storm-ui';
import styled from 'styled-components';
import { DisplayStatusText } from 'components/home/DisplayStatusText';
import { getDisplayStatus } from 'utils/status.utils';

interface DeckStatusWidgetCardProps {
    id: number;
    name: string;
    description?: string;
    deckName: string;
    status: Status;
    step: Step;
    updateTimestamp: string;
    parameters: {
        [key: string]: string | undefined;
    };
}

const DeckStatusWidgetCardContainer = styled.div`
    border: 1px solid ${({ theme }) => theme.palette.blue[100]};
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 8px;
    padding: 4px;
`;

const TruncateText = styled(Text)`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

const NameAndDescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    height: 25%;
`;

const AdditionalDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    height: 40%;
`;

const StatusContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: end;
    height: 25%;
`;

export const DeckStatusWidgetCard: React.FunctionComponent<
    DeckStatusWidgetCardProps
> = (props: DeckStatusWidgetCardProps) => {
    const {
        name,
        description,
        deckName,
        status,
        step,
        parameters,
        updateTimestamp,
        id,
    } = props;
    const displayStatus = getDisplayStatus(status, step);
    return (
        <DeckStatusWidgetCardContainer>
            <NameAndDescriptionContainer>
                <Text
                    data-testid={`deckStatusWidgetCardName-${id}`}
                    type="h3"
                    textColor="base"
                    fontSize="mini"
                >
                    <strong>{name}</strong>
                </Text>
                <TruncateText
                    data-testid={`deckStatusWidgetCardDescription-${id}`}
                    type="h4"
                    textColor="secondary"
                    fontSize="mini"
                >
                    {description}
                </TruncateText>
            </NameAndDescriptionContainer>
            <AdditionalDetailsContainer>
                <TruncateText
                    data-testid={`deckStatusWidgetCardDeckName-${id}`}
                    type="p"
                    textColor="secondary"
                    fontSize="mini"
                >
                    <strong>Deck Name: </strong>
                    {deckName}
                </TruncateText>
                {parameters.advertiser_name !== undefined && (
                    <TruncateText
                        data-testid={`deckStatusWidgetCardAdvertiser-${id}`}
                        type="p"
                        textColor="secondary"
                        fontSize="mini"
                    >
                        <strong>Advertiser: </strong>
                        {parameters.advertiser_name}
                    </TruncateText>
                )}
                {parameters.locale !== undefined && (
                    <TruncateText
                        data-testid={`deckStatusWidgetCardLocale-${id}`}
                        type="p"
                        textColor="secondary"
                        fontSize="mini"
                    >
                        <strong>Locale: </strong>
                        {parameters.locale}
                    </TruncateText>
                )}
            </AdditionalDetailsContainer>
            <StatusContainer>
                <Text
                    data-testid={`deckStatusWidgetCardStatus-${id}`}
                    type="p"
                    textColor="secondary"
                    fontSize="mini"
                >
                    Status:{' '}
                    {<DisplayStatusText displayStatus={displayStatus} />} |
                    Created: {updateTimestamp}
                </Text>
            </StatusContainer>
        </DeckStatusWidgetCardContainer>
    );
};
