import * as React from 'react';
import styled from 'styled-components';

import { DisplayStatus } from 'types/Status';

const GreenText = styled.span`
    color: ${({ theme }) => theme.palette.apple};
`;

const RedText = styled.span`
    color: ${({ theme }) => theme.palette.auired};
`;

const BlueText = styled.span`
    color: ${({ theme }) => theme.palette.auiblue};
`;

export interface DisplayStatuTextsProps {
    readonly displayStatus: DisplayStatus;
}

export const DisplayStatusText: React.FunctionComponent<
    DisplayStatuTextsProps
> = (props: DisplayStatuTextsProps) => {
    const { displayStatus } = props;
    if (displayStatus === DisplayStatus.SUCCESS)
        return <GreenText>{DisplayStatus.SUCCESS}</GreenText>;
    if (displayStatus === DisplayStatus.FAIL)
        return <RedText>{DisplayStatus.FAIL}</RedText>;
    return <BlueText>{DisplayStatus.IN_PROGRESS}</BlueText>;
};
