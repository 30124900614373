export const SIM_T_LINK = 'https://t.corp.amazon.com/create/templates';

export const buildMissingTextWithSimTemplate = (
    label: string,
    templateId?: string
): string | undefined => {
    if (templateId !== undefined) {
        return `Please raise a ticket [here](${SIM_T_LINK}/${templateId}) if your ${label} is missing`;
    }
    return undefined;
};
