import { Deck } from 'types/Deck';

/**
 * Sorts deck array Alphabetically with Recommended first
 */
export const sortDecks = (decks: Deck[]) => {
    return decks.sort((a, b) =>
        a.recommended === b.recommended
            ? a.name.localeCompare(b.name)
            : a.recommended
            ? -1
            : 1
    );
};
