import React from 'react';
import { HashRouter } from 'react-router-dom';

import { fetchUsername, fetchSettings } from 'retrievers/auth';
import RoutePaths from 'RoutePaths';
import { useAuthStore } from 'store/auth';
import { AlertType, useAlertStore } from 'store/alert';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { Alert } from 'components/common/Alert';

interface AppProps {}

const App: React.FunctionComponent<AppProps> = () => {
    const [loading, setLoading] = React.useState(true);
    const [setAlert] = useAlertStore((state) => [state.setAlert]);
    const [authState] = useAuthStore((state) => [state]);
    React.useEffect(() => {
        (async () => {
            try {
                await Promise.all([
                    fetchUsername(authState),
                    fetchSettings(authState),
                ]);
            } catch (e: any) {
                setAlert(e.message, AlertType.ERROR, e.stack);
            }
            setLoading(false);
        })();
    }, []);

    return (
        <HashRouter>
            <Alert />
            {loading ? <LoadingSpinner /> : <RoutePaths />}
        </HashRouter>
    );
};

export default App;
