import * as React from 'react';
import { Text, Icon, Button, Card, CardHeader, Divider } from '@amzn/storm-ui';
import { times } from '@amzn/storm-ui-icons';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { DimensionTemplateBuilder } from 'components/dimensions/DimensionTemplatesBuilder';
import { useAsync, useMountEffect, useIntervalEffect } from '@react-hookz/web';
import { useDimensionStatusStore } from 'store/dimension.status';
import { useDimensionValueStore } from 'store/dimension.value';
import {
    NON_SELECTED_DECK,
    ObjectivesPanel,
} from 'components/createDeck/ObjectivesPanel';
import { Deck } from 'types/Deck';
import { getLabelsFromDimensionTemplates, useQuery } from 'utils/app.utils';
import { useGetRequestParameters } from 'utils/dimension.hooks';
import { getStateKeys } from 'store/zustand.helpers';
import {
    createDeck,
    getDomain,
    getDecks,
    getDeckStatuses,
} from 'retrievers/api';
import { AlertType, useAlertStore } from 'store/alert';
import { useNavigate } from 'react-router-dom';
import { HelpComponent } from 'components/createDeck/HelpComponent';
import AdaptiveHelpTip from '@amzn/storm-ui-adaptive-helptip';
import { Comparator } from 'types/Common';
import { useCtiStore } from 'store/cti';

interface CreateDeckContainerProps {}

const Topbar = styled.div`
    position: sticky;
    top: 0;
    height: 45px;
    padding: 10px 25px;
    background: #fbfbfc;
    border-bottom: 2px solid #d9dee4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
`;

const TopbarGrouping = styled.div`
    display: flex;
    align-items: center;
    gap: 14px;
`;

const CardContainer = styled.div`
    background: #fbfbfc;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    min-height: 100vh;
`;
export const DimensionsCard = styled(Card)`
    max-width: 900px;
    margin-bottom: 20px;
`;

const InstructionDiv = styled.div`
    background: ${({ theme }) => theme.palette.gray[10]};
    display: flex;
    justify-content: center;
    padding-top: 40px;
`;

const InstructionContainer = styled.div`
    max-width: 900px;
    width: 100%;
`;

export const CreateDeckContainer: React.FunctionComponent<
    CreateDeckContainerProps
> = () => {
    const { domainId = '' } = useParams();
    const navigate = useNavigate();
    const queryParameters = useQuery();

    const [setAlert] = useAlertStore((state) => [state.setAlert]);
    const [setCti] = useCtiStore((state) => [state.setCti]);
    const [deckId, setDeckId] = React.useState<number>(NON_SELECTED_DECK);

    const [state, actions] = useAsync(async () => {
        const { data } = await getDomain(domainId);
        setCti(data.cti);
        return data;
    });

    const [decks, setDecks] = React.useState<Deck[] | undefined>(undefined);

    const [decksLoading, setDecksLoading] = React.useState<boolean>(false);

    const executionId = React.useRef('');

    //Needed because it may take a few getReportStatus calls before success
    const [reportStatusLoading, setReportStatusLoading] =
        React.useState<boolean>(false);

    useMountEffect(actions.execute);
    React.useEffect(() => {
        if (state.error) {
            setAlert(state.error.message, AlertType.ERROR, state.error.stack);
        }
    }, [state.status]);

    const domainName = React.useMemo(() => {
        return state.result?.name || '';
    }, [state.result]);

    const [
        enableApplySettings,
        resetCurrentDimensionsStatus,
        unsucessfulStatus,
    ] = useDimensionStatusStore((state) => [
        state.checkAllStatusSuccess(),
        state.resetCurrentDimensions,
        state.getAllNonSuccessStatus(),
    ]);

    const [resetCurrentDimensionsValue, dimensionsIds] = useDimensionValueStore(
        (state) => [state.resetCurrentDimensions, getStateKeys(state)]
    );

    const [dimensionsMapFilter] = useDimensionValueStore((state) => [
        state.getDimensionValueFilters(dimensionsIds),
    ]);

    const resetCurrentDimensions = () => {
        resetCurrentDimensionsStatus();
        resetCurrentDimensionsValue();
        setDeckId(NON_SELECTED_DECK);
        setDecks([]);
    };

    const currentRequestParameters = useGetRequestParameters(dimensionsIds);

    const handleApplySettings = async () => {
        try {
            setDecksLoading(true);
            const response = await getDecks(domainId, {
                additionalFilters: dimensionsMapFilter,
            });
            setDecks(response.data);
        } catch (e: any) {
            setAlert(e.message, AlertType.ERROR, e.stack);
        }
        setDecksLoading(false);
    };

    const intervalRefreshRateMS = 2000; // 2 seconds;

    const [deckStatusState, deckStatusAction] = useAsync(async () => {
        if (!isEmpty(executionId.current)) {
            try {
                const { data } = await getDeckStatuses({
                    additionalFilters: [
                        {
                            key: 'execution_id',
                            comparator: Comparator.EQUALS,
                            value: executionId.current,
                        },
                    ],
                });
                if (!isEmpty(data!)) {
                    setReportStatusLoading(false);
                    setAlert(
                        `Your request has been submitted successfully with Execution ID: ${executionId.current}!`,
                        AlertType.SUCCESS
                    );
                    navigate('/');
                }
            } catch (error: any) {
                setAlert(error.message, AlertType.ERROR, error.stack);
            }
        }
    });

    useIntervalEffect(() => {
        deckStatusAction.execute();
    }, intervalRefreshRateMS);

    const [deckRequestState, deckRequestAction] = useAsync(async () => {
        try {
            const data = await createDeck({
                domainId,
                deckId: deckId.toString(),
                duplicateCheckEnabled: true,
                parameters: currentRequestParameters,
            });
            executionId.current = data.executionId!;
            setReportStatusLoading(true);
            deckStatusAction.execute();
        } catch (error: any) {
            setAlert(error.message, AlertType.ERROR, error.stack);
        }
    });

    const disableGenerateDeck = [
        state.status === 'success',
        enableApplySettings,
        deckId !== NON_SELECTED_DECK,
    ].some((e) => e === false);

    return (
        <div style={{ margin: -14 }}>
            <Topbar>
                <TopbarGrouping>
                    <Icon
                        data-testid="exitCreateDeck"
                        type={times}
                        onClick={() => navigate('/')}
                        style={{ cursor: 'pointer' }}
                    />
                    <Text type="span" fontSize="medium">
                        {state.status === 'success'
                            ? `New Pitch Deck : ${domainName}`
                            : `Loading`}
                    </Text>
                </TopbarGrouping>
                <TopbarGrouping>
                    <Button
                        type="reset"
                        primary
                        transparent
                        onClick={resetCurrentDimensions}
                    >
                        Reset All
                    </Button>
                    <Button
                        data-testid="generateDeck"
                        type="submit"
                        primary
                        disabled={disableGenerateDeck}
                        loading={
                            deckRequestState.status === 'loading' ||
                            deckStatusState.status === 'loading' ||
                            reportStatusLoading
                        }
                        onClick={deckRequestAction.execute}
                    >
                        Generate Deck
                    </Button>
                    <HelpComponent details={state.result?.additionalDetails} />
                </TopbarGrouping>
            </Topbar>
            <InstructionDiv>
                <InstructionContainer>
                    <Text data-testid="instruction">
                        Please complete all relevant fields below and click
                        <strong> Apply Settings</strong> to see list of relevant
                        decks available for your use.
                    </Text>
                </InstructionContainer>
            </InstructionDiv>

            <CardContainer>
                {state.status === 'success' && (
                    <DimensionsCard>
                        <CardHeader>
                            <Text type="h2">Settings</Text>
                        </CardHeader>
                        <DimensionTemplateBuilder
                            dimensionSections={state.result?.dimensions}
                        />
                        <Divider />
                        <Button
                            data-testid="applySettings"
                            disabled={!enableApplySettings}
                            onClick={handleApplySettings}
                            primary
                        >
                            Apply Settings
                        </Button>
                        {!enableApplySettings && (
                            <AdaptiveHelpTip>
                                <div>
                                    <Text>Please complete required fields</Text>
                                    <Divider />
                                    {getLabelsFromDimensionTemplates(
                                        unsucessfulStatus,
                                        state.result!.dimensions
                                    ).map((e, i) => (
                                        <Text type="p" fontSize="mini" key={i}>
                                            {e}
                                        </Text>
                                    ))}
                                </div>
                            </AdaptiveHelpTip>
                        )}
                    </DimensionsCard>
                )}
                {queryParameters.get('showRequestParameters') && (
                    <DimensionsCard>
                        <CardHeader>
                            <Text type="h2">Request Parameters</Text>
                        </CardHeader>
                        <pre>
                            {JSON.stringify(currentRequestParameters, null, 4)}
                        </pre>
                    </DimensionsCard>
                )}
                <ObjectivesPanel
                    handleDeckSelection={(id) => setDeckId(id)}
                    decks={decks}
                    loading={decksLoading}
                />
            </CardContainer>
        </div>
    );
};
