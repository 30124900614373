import * as React from 'react';
import styled from 'styled-components';
import { Text } from '@amzn/storm-ui';
import { DeckStatusCard } from './DeckStatusCard';
import { DeckStatus } from 'types/Status';

const DeckStatusViewerContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

const DeckStatusCardContainer = styled.div`
    list-style: none;
    flex: 0 0 30%;
    box-sizing: border-box;
    padding: 15px;
    max-width: 30%;
`;

interface DeckStatusViewerProps {
    deckStatuses: DeckStatus[];
}

export const DeckStatusViewer: React.FunctionComponent<
    DeckStatusViewerProps
> = (props: DeckStatusViewerProps) => {
    const { deckStatuses } = props;
    return (
        <DeckStatusViewerContainer>
            {deckStatuses.length > 0 ? (
                deckStatuses.map((deck, i) => {
                    const {
                        name,
                        description,
                        deckName,
                        status,
                        step,
                        updateTimestamp,
                        executionId,
                        deckId,
                        parameters,
                    } = deck;
                    return (
                        <DeckStatusCardContainer
                            key={i}
                            id={`deckStatusCard-${i}`}
                            data-testid={`deckStatusCard-${i}`}
                        >
                            <DeckStatusCard
                                id={i}
                                parametersPosition={i < 3 ? 'bottom' : 'top'}
                                deckId={deckId}
                                name={name}
                                description={description}
                                deckName={deckName}
                                status={status}
                                step={step}
                                executionId={executionId}
                                updateTimestamp={updateTimestamp}
                                parameters={parameters}
                            />
                        </DeckStatusCardContainer>
                    );
                })
            ) : (
                <Text>No Decks</Text>
            )}
        </DeckStatusViewerContainer>
    );
};
