import { AuthState, AuthActions, AuthSettings } from 'store/auth';
import { getWebsiteSettings } from 'clients/websiteSettings';
import { getUsername } from 'clients/credentials';
import { Write } from 'store/zustand.helpers';

export const fetchUsername = async (
    authState: Write<AuthState, AuthActions>
) => {
    const username = await getUsername();
    authState.setUsername(username);
};

export const fetchSettings = async (
    authState: Write<AuthState, AuthActions>
) => {
    const settings: AuthSettings = await getWebsiteSettings();
    authState.setSettings(settings);
};
