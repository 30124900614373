import React from 'react';
import {
    dimensionValueStore,
    useDimensionValueStore,
} from 'store/dimension.value';
import { useEffectOnce } from 'react-use';
import {
    dimensionStatusStore,
    useDimensionStatusStore,
} from 'store/dimension.status';
import {
    DimensionTemplateBuilder,
    DimensionTemplateBuilderHelper,
} from 'components/dimensions/DimensionTemplatesBuilder';

import {
    BgfCorePitchDimensionsTemplate,
    dropdownTest,
} from 'fixtures/exampleTemplates';
import { useGetRequestParameters } from 'utils/dimension.hooks';
import { APPENV } from 'utils/app.utils';

export const TestContainer: React.FunctionComponent = () => {
    const [valueState] = useDimensionValueStore((state) => [
        state,
        state.setData,
    ]);
    const [statusState] = useDimensionStatusStore((state) => [state]);
    useEffectOnce(() => {
        dimensionValueStore.setState({});
        dimensionStatusStore.setState({});
    });
    const rq = useGetRequestParameters(
        DimensionTemplateBuilderHelper.getDimensionIds(
            BgfCorePitchDimensionsTemplate
        )
    );
    return (
        <div>
            {APPENV.mock_server && 'mock server is active'}
            <h4> test</h4>
            <div> Value state </div>
            <pre> {JSON.stringify(valueState, null, 4)} </pre>
            <div> Status state </div>
            <pre> {JSON.stringify(statusState, null, 4)} </pre>
            <div> request parameter</div>
            <pre> {JSON.stringify(rq, null, 4)} </pre>
            <DimensionTemplateBuilder dimensionSections={dropdownTest} />
        </div>
    );
};
