import React from 'react';
import { Dropdown, DropdownItem } from '@amzn/storm-ui';
import styled from 'styled-components';
import { sortBy } from 'lodash';
import { Program } from 'types/Program';

const ProgramDropDownContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
`;

export interface ProgramDropdownProps {
    programs: Program[];
    program: Program;
    setProgram: (program: Program) => void;
}

export const ALL_DOMAINS_PROGRAM: Program = {
    id: -1,
    name: 'View all domains',
    description: 'View all domains',
};

export const ProgramDropdown: React.FunctionComponent<ProgramDropdownProps> = (
    props: ProgramDropdownProps
) => {
    const { program, programs, setProgram } = props;

    const ProgramSelector = () => {
        const programItems = sortBy(programs, ['name']).map(
            (program, index) => (
                <DropdownItem key={`program-${index}`} value={program}>
                    {program.name}
                </DropdownItem>
            )
        );

        return (
            <Dropdown
                selectedValue={program}
                label="Filter content type"
                inline
                onChange={setProgram}
                data-testid="programDropdown"
            >
                {programs.length === 0 ? (
                    <DropdownItem value="n/a" disabled>
                        No Content Types
                    </DropdownItem>
                ) : (
                    [
                        <DropdownItem
                            key="all-domains"
                            value={ALL_DOMAINS_PROGRAM}
                        >
                            {ALL_DOMAINS_PROGRAM.name}
                        </DropdownItem>,
                        ...programItems,
                    ]
                )}
            </Dropdown>
        );
    };

    return (
        <ProgramDropDownContainer>
            <ProgramSelector />
        </ProgramDropDownContainer>
    );
};
