import { DateComponent, DateComponentProps } from 'components/common/Date';
import React, { useMemo } from 'react';
import { DimensionStatus } from 'store/dimension.status';
import { DateDimensionValue } from 'store/dimension.value';
import { DimensionConfiguration, DimensionTemplate } from 'types/Dimensions';
import {
    useGetDimensionDisable,
    useSetDimensionValue,
    useUpdateStatusOnChange,
    clearDimensionValueOnDependencyChange,
    useGetRequestParameters,
} from 'utils/dimension.hooks';
import { buildRequestParameter } from 'utils/app.utils';

export interface DateDimensionConfiguration extends DimensionConfiguration {
    dateFormat: string;
    startRange?: string;
}

export interface DateDimensionProps extends DimensionTemplate {
    configuration: DateDimensionConfiguration;
}

export const DateDimension: React.FunctionComponent<DateDimensionProps> = (
    props
) => {
    const { id, dependencies, configuration, optional = false } = props;

    const { label, dateFormat, startRange, tooltip } = configuration;

    const rpMap = useMemo(() => {
        return { value: `${id}` };
    }, []);

    const currentRequestParameters = useGetRequestParameters(dependencies);

    const disabled = useGetDimensionDisable(dependencies);

    const [dimensionData, setDimensionData] =
        useSetDimensionValue<DateDimensionValue>(
            id,
            buildRequestParameter(rpMap)
        );

    useUpdateStatusOnChange<DateDimensionValue>(id, ({ value }) => {
        if (optional) return DimensionStatus.SUCCESS;
        if (value.length === 0) {
            return DimensionStatus.EMPTY;
        }
        return DimensionStatus.SUCCESS;
    });

    clearDimensionValueOnDependencyChange(id, dependencies);

    const getStartRangeValue = () => {
        const requestParameterStartValue: string =
            startRange && currentRequestParameters[startRange]
                ? currentRequestParameters[startRange]
                : '';
        return requestParameterStartValue;
    };

    const dateComponentProps: DateComponentProps = {
        dimension: id,
        value: dimensionData.value,
        setValue: (value) => setDimensionData({ value } as DateDimensionValue),
        disabled,
        label,
        dateFormat,
        startRange: getStartRangeValue(),
        optional,
        tooltip,
    };

    return <DateComponent {...dateComponentProps} />;
};
