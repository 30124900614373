import styled from 'styled-components';

export const ComponentLabel = styled.span`
    display: inline;
    font-size: 13px;
    line-height: 1.465;
    font-family: 'Amazon Ember', Helvetica, sans-serif;
    padding: 0px;
    font-weight: 700;
    color: rgb(35, 47, 63);
`;
