import { DropdownDimensionProps } from 'components/dimensions/DropdownDimension';
import { InputDimensionProps } from 'components/dimensions/InputDimension';
import { DateDimensionProps } from 'components/dimensions/DateDimension';
import { DataDimensionProps } from 'components/dimensions/DataDimension';
import { StaticDropdownDimensionProps } from 'components/dimensions/StaticDropdownDimension';

export enum DimensionTypes {
    INPUT = 'input',
    DROPDOWN = 'dropdown',
    STATIC_DROPDOWN = 'static_dropdown',
    DATE = 'date',
    MARKDOWN = 'markdown',
    DATA = 'data',
}

export interface DimensionConfiguration {
    label: string;
    tooltip?: string;
}

export type Dimension = string;

export type DimensionTemplates =
    | InputDimensionProps
    | DropdownDimensionProps
    | StaticDropdownDimensionProps
    | DateDimensionProps
    | DataDimensionProps;

export interface RequestParameterMapper {
    value: string;
}

export interface DimensionTemplate {
    id: Dimension;
    type: DimensionTypes;
    configuration: DimensionConfiguration;
    dependencies: Dimension[];
    optional?: boolean;
    requestParameterMap?: Record<string, string>;
}

export const DimensionInitIgnore = [DimensionTypes.MARKDOWN];
