import * as React from 'react';
import { RouteObject, Navigate, useRoutes } from 'react-router-dom';

import { HomeContainer, CreateDeckContainer, TestContainer } from 'containers';

const routes: RouteObject[] = [
    {
        path: '/domains/:domainId/deck',
        element: <CreateDeckContainer />,
    },
    {
        path: '/test',
        element: <TestContainer />,
    },
    { path: '/', element: <HomeContainer /> },
    { path: '/*', element: <Navigate to="/" replace /> },
    { path: '*', element: <Navigate to="/" replace /> },
];

const wildcardMatcher = /^#\//;

const RoutePaths: React.FunctionComponent = () => {
    const elements = useRoutes(routes);
    React.useEffect(() => {
        if (!wildcardMatcher.test(window.location.hash)) {
            window.location.hash = '';
        }
    }, [window.location.hash]);
    return elements;
};

export default RoutePaths;
