import { Comparator, Filter, OrderBy } from 'types/Common';
import { DataSource, BaseDataSourceConfiguration } from '.';
import { getDropdownValues } from 'retrievers/api';

export interface InputTableFilter {
    column: string;
    requestParameterKey: string;
    comparator: Comparator;
}

export interface CdbInputTableSettings {
    tableName: string;
    filters: InputTableFilter[];
    orderBy: OrderBy[];
    columns: string[];
}

export interface CdbInputTableConfiguration
    extends BaseDataSourceConfiguration {
    type: DataSource.CDB_INPUT_TABLE;
    settings: CdbInputTableSettings;
    requirements: string[];
}

export const getCdbInputTableData = async (
    settings: CdbInputTableSettings,
    inputPayload: any
) => {
    const { tableName, filters, orderBy, columns } = settings;

    // convert input table filter to filter
    // TODO: add check to make sure requestparamter key exist
    const additionalFilters: Filter[] = filters.map((filter) => ({
        key: filter.column,
        value: inputPayload[filter.requestParameterKey],
        comparator: filter.comparator,
    }));

    const { data } = await getDropdownValues({
        datasetName: tableName,
        columnNames: columns.join(','),
        additionalFilters,
        orderBy,
    });

    return data;
};
