import { APPENV } from 'utils/app.utils';

export const API_PATH_PREFIX = '/deckBuilder';

export const generateUrl = (
    url: string,
    path: string | undefined = undefined,
    searchParams: URLSearchParams | undefined = undefined
): string => {
    const urlWithProtocol = generateUrlWithProtocol(url);
    const pathWithQuery = generatePathWithQuery(path, searchParams);
    return [urlWithProtocol, pathWithQuery].join('');
};

export const generateUrlWithProtocol = (url: string): string => {
    const protocol = APPENV.mock_server ? 'http' : 'https';
    const urlWithProtocol = [`${protocol}://`, url].join('');
    return urlWithProtocol;
};

export const generatePathWithQuery = (
    path: string | undefined = undefined,
    searchParams: URLSearchParams | undefined = undefined
): string => {
    const pathWithExtension = [API_PATH_PREFIX, path].join('');
    return [pathWithExtension, searchParams].filter(Boolean).join('?');
};
