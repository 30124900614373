import * as React from 'react';
import { Button } from '@amzn/storm-ui';
import { DomainModal } from 'components/home/DomainModal';
import styled from 'styled-components';
import { getDomains, getPrograms } from 'retrievers/api';
import { Domain } from 'types/Domain';
import { Program } from 'types/Program';
import { AlertType, useAlertStore } from 'store/alert';
import { ALL_DOMAINS_PROGRAM } from 'components/home/ProgramDropdown';

const ActionPanelContainer = styled.div`
    display: flex;
    > * {
        padding-right: 20px;
    }
`;

interface ActionPanelProps {}

export const ActionPanel: React.FunctionComponent<ActionPanelProps> = () => {
    // const [searchValue, setSearchValue] = React.useState("");
    const [isModalOpen, setIsModelOpen] = React.useState(false);
    const [domains, setDomains] = React.useState<Domain[] | undefined>(
        undefined
    );
    const [programs, setPrograms] = React.useState<Program[]>([]);
    const [program, setProgram] = React.useState<Program>(ALL_DOMAINS_PROGRAM);
    const [alertState] = useAlertStore((state) => [state]);

    React.useEffect(() => {
        (async () => {
            try {
                const [domainsResponse, programsResponse] = await Promise.all([
                    getDomains({}, 0, 100),
                    getPrograms(),
                ]);
                setPrograms(programsResponse.data);
                setDomains(domainsResponse.data);
            } catch (e: any) {
                alertState.setAlert(e.message, AlertType.ERROR);
            }
        })();
    }, []);

    const toggleModal = (): void => {
        setIsModelOpen(!isModalOpen);
    };

    // const handleFilter = (): void => {
    //     console.log("I need to filter");
    // };

    // const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    //     setSearchValue(event.target.value || "");
    //     console.log(event.target.value);
    // };

    // const handleClear = (): void => {
    //     setSearchValue("");
    // };

    return (
        <>
            <DomainModal
                isModalOpen={isModalOpen}
                toggleModal={toggleModal}
                domains={domains || []}
                programs={programs}
                activeProgram={program}
                setProgram={setProgram}
            />
            <ActionPanelContainer>
                <div>
                    <Button
                        id="createPitchDeck"
                        data-testid="createPitchDeck"
                        onClick={toggleModal}
                        disabled={domains === undefined}
                        primary
                    >
                        Create Pitch Deck
                    </Button>
                </div>
                {/* <SearchInput
                    id="searchPitchDeck"
                    data-testid="searchPitchDeck"
                    placeholder="Search"
                    value={searchValue}
                    onChange={handleChange}
                    onClear={handleClear}
                />
                <TextButton
                    id="filterPitchDeck"
                    data-testid="filterPitchDeck"
                    onClick={handleFilter}
                >
                    Filter
                </TextButton> */}
            </ActionPanelContainer>
        </>
    );
};
