import {
    InputComponent,
    InputComponentProps,
    InputComponentTypes,
} from 'components/common/Input';
import React, { useMemo } from 'react';
import { DimensionStatus } from 'store/dimension.status';
import { InputDimensionValue } from 'store/dimension.value';
import { RequestParameters } from 'types/Common';
import { DimensionConfiguration, DimensionTemplate } from 'types/Dimensions';
import {
    useGetDimensionDisable,
    useSetDimensionValue,
    useUpdateStatusOnChange,
} from 'utils/dimension.hooks';

export interface InputDimensionConfiguration extends DimensionConfiguration {
    validation?: InputValidationOptions;
    type: InputComponentTypes;
}

export interface InputDimensionProps extends DimensionTemplate {
    configuration: InputDimensionConfiguration;
}

export interface InputValidationOptions {
    regex?: {
        expression: string;
        errorMessage: string;
    };
}

export const InputDimensionHelpers = {
    buildRequestParameters: (
        dim: string,
        type: InputComponentTypes,
        requestParameterMap: Record<string, string> | undefined,
        v: InputDimensionValue
    ): RequestParameters => {
        const value = InputDimensionHelpers.processValue(type, v.value);
        return { [requestParameterMap?.value || dim]: value };
    },

    processValue: (type: InputComponentTypes, value: string): string => {
        if (type === InputComponentTypes.PERCENT) {
            return (parseFloat(value) / 100).toString();
        }
        return value;
    },
    processStatus: (
        value: string,
        validationOptions: InputValidationOptions = {},
        optional?: boolean
    ): [DimensionStatus, string] => {
        if (value.length === 0 && !optional) {
            return [DimensionStatus.EMPTY, ''];
        }
        if (validationOptions.regex) {
            if (value.length === 0 && optional) {
                return [DimensionStatus.SUCCESS, ''];
            }
            const regexp = new RegExp(validationOptions.regex.expression);
            if (!regexp.test(value)) {
                return [
                    DimensionStatus.INVALID,
                    validationOptions.regex.errorMessage,
                ];
            }
        }
        return [DimensionStatus.SUCCESS, ''];
    },
};

export const InputDimension: React.FunctionComponent<InputDimensionProps> = (
    props
) => {
    const { id, dependencies, configuration, optional, requestParameterMap } =
        props;

    const {
        label,
        type: inputComponentType,
        validation,
        tooltip,
    } = configuration;

    const [dimensionData, setDimensionsValue] = useSetDimensionValue(
        id,
        (value) =>
            InputDimensionHelpers.buildRequestParameters(
                id,
                inputComponentType,
                requestParameterMap,
                value
            )
    );

    const disabled = useGetDimensionDisable(dependencies);

    const [dimensionStatus, warningMessage] = useMemo(
        () =>
            InputDimensionHelpers.processStatus(
                dimensionData.value,
                validation,
                optional
            ),
        [dimensionData.value]
    );

    useUpdateStatusOnChange(id, () => dimensionStatus);

    const inputComponentProps: InputComponentProps = {
        dimension: id,
        value: dimensionData.value,
        setValue: (value) => setDimensionsValue({ value }),
        disabled,
        label: label,
        type: inputComponentType,
        tooltip,
        warningMessage,
        optional,
    };

    return <InputComponent {...inputComponentProps} />;
};
