export enum Step {
    ARTIFACTS_GENERATION = 'ARTIFACTS_GENERATION',
    VISUALIZATION = 'VISUALIZATION',
}

export enum Status {
    WAITING = 'WAITING',
    IN_PROGRESS = 'IN_PROGRESS',
    SUCCESS = 'SUCCESS',
    FAIL = 'FAIL',
    EXPIRED = 'EXPIRED',
}

export enum DisplayStatus {
    IN_PROGRESS = 'In Progress',
    SUCCESS = 'Success',
    FAIL = 'Fail',
}

export interface DeckStatus {
    id: number;
    deckId: number;
    name: string;
    description?: string;
    deckName: string;
    status: Status;
    step: Step;
    executionId: string;
    updateTimestamp: string;
    parameters: {
        [key: string]: string | undefined;
    };
}

export interface ReportStatus {
    deckId: string;
    deckName: string;
    parameters: {
        [key: string]: string | undefined;
    };
    status: Status;
    step: Step;
    executionId: string;
    updateTimestamp: string;
    requesterAlias?: string;
}
