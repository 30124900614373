import { CdbApiRetriever } from 'retrievers/cdbApi';
import { AdsApiRetriever } from 'retrievers/adsApi';
import { ApiRetriever } from 'types/retrievers';

export const amhToCdbOriginLookup: Record<string, string> = {
    'amh-na.integ.amazon.com': 'beta.console.cdb.ads.amazon.dev',
    'amh-na-preprod-iad.iad.proxy.amazon.com':
        'gamma.console.cdb.ads.amazon.dev',
    'amh-eu-preprod-dub.dub.proxy.amazon.com':
        'gamma.console.cdb.ads.amazon.dev',
    'amh-fe-preprod-pdx.pdx.proxy.amazon.com':
        'gamma.console.cdb.ads.amazon.dev',
    'amh.aka.amazon.com': 'console.cdb.ads.amazon.dev',
    'amh.amazon.co.uk': 'console.cdb.ads.amazon.dev',
    'amh.amazon.co.jp': 'console.cdb.ads.amazon.dev',
};

export const atcToCdbOriginLookup: Record<string, string> = {
    'advertising.integ.amazon.com':
        'corp.d16gcs-d16gcdbco-f1paoj1hi2.iad.beta.hex.a2z.com',
    'advertising-uk-preprod.amazon.com':
        'corp.d16gcs-d16gcdb-g-zxgchbrkhe.dub.gamma.hex.a2z.com',
    'advertising-jp-preprod-pdx.pdx.proxy.amazon.com':
        'corp.d16gcs-d16gcdb-g-b06snruvbk.pdx.gamma.hex.a2z.com',
    'advertising-preprod.amazon.com':
        'corp.d16gcs-d16gcdb-g-jnsbpmwkv7.iad.gamma.hex.a2z.com',
    'advertising.amazon.co.jp':
        'corp.d16gcs-d16gcdb-p-gmsw2e2lwo.pdx.prod.hex.a2z.com',
    'advertising.amazon.co.uk':
        'corp.d16gcs-d16gcdb-p-idn3x8lss0.dub.prod.hex.a2z.com',
    'advertising.amazon.com':
        'corp.d16gcs-d16gcdb-p-pzcsbxwtpz.iad.prod.hex.a2z.com',
};

export type SettingsOrigin = {
    host: string;
    retriever: ApiRetriever;
};

export const cdbPathInAtc = 'client-deck-builder';
export const getSettingsOrigin = (): SettingsOrigin => {
    const host: string = window.location.hostname;
    if (Object.keys(amhToCdbOriginLookup).includes(host)) {
        return {
            host: amhToCdbOriginLookup[host],
            retriever: new CdbApiRetriever(),
        };
    }

    if (Object.keys(atcToCdbOriginLookup).includes(host)) {
        return {
            host: `${host}/${cdbPathInAtc}`,
            retriever: new AdsApiRetriever(),
        };
    }
    return {
        host,
        retriever: new CdbApiRetriever(),
    };
};
