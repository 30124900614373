import * as React from 'react';
import { HelpTip } from '@amzn/storm-ui';
import { Text } from '@amzn/storm-ui';
import ReactMarkdown from 'react-markdown';

interface LabelEndComponentProps {
    tooltip?: string;
    optional?: boolean;
}

export const LabelEndComponent: React.FunctionComponent<
    LabelEndComponentProps
> = (props) => {
    const { tooltip, optional } = props;

    return (
        <>
            {tooltip && (
                <HelpTip position="right">
                    <ReactMarkdown linkTarget="_blank">{tooltip}</ReactMarkdown>
                </HelpTip>
            )}
            {optional && (
                <Text type="span" fontSize="small" textColor="secondary">
                    Optional
                </Text>
            )}
        </>
    );
};
