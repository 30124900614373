import { AuthSettings } from 'store/auth';

export const devSettings: AuthSettings = {
    adsApiEndpoint: 'advertising.integ.amazon.com/a9g-api-gateway',
    apiEndpoint: 'us-east-1.dev-rwenxiao.api.cdb.ads.amazon.dev',
    region: 'us-east-1',
    cognitoPoolId: 'us-east-1:8e19a4fc-174a-4b02-9d7e-9833add6aaf3',
    // Uncomment line below to test with TADA Advertising API
    // retriever: new AdsApiRetriever(),
};
