import { apiGet, apiPost } from 'clients/aws4Fetch';
import * as API from 'types/api';
import { DeckStatus, ReportStatus } from '../types/Status';
import {
    formatDeckStatusDateTime,
    DATE_FORMAT,
} from '../utils/deckStatus.utils';
import { format } from 'date-fns';
import { ApiRetriever, Pagination, DateRange } from '../types/retrievers';

export class CdbApiRetriever implements ApiRetriever {
    constructor() {}

    getDomains = async (
        body: API.GetDomainsRequest = {},
        pagination: Pagination
    ): Promise<API.GetDomainsResponse> => {
        return apiPost({
            path: '/domains',
            searchParams: new URLSearchParams({
                pageSize: pagination.pageSize.toString(),
                pageToken: pagination.pageToken.toString(),
            }),
            body,
        });
    };

    getDomain = async (id: string): Promise<API.GetDomainResponse> => {
        return apiGet({
            path: `/domains/${id}`,
        });
    };

    getDecks = async (
        id: string,
        body: API.GetDecksRequest = {},
        pagination: Pagination
    ): Promise<API.GetDecksResponse> => {
        return apiPost({
            path: `/domains/${id}/decks`,
            searchParams: new URLSearchParams({
                pageSize: pagination.pageSize.toString(),
                pageToken: pagination.pageToken.toString(),
            }),
            body,
        });
    };

    getDropdownValues = async (
        body: API.GetDropdownValuesRequest,
        pagination: Pagination
    ): Promise<API.GetDropdownValuesResponse> => {
        return apiPost({
            path: `/dropdownValues`,
            searchParams: new URLSearchParams({
                pageSize: pagination.pageSize.toString(),
                pageToken: pagination.pageToken.toString(),
            }),
            body,
        });
    };

    createDownload = async (
        deckId: number,
        executionId: string
    ): Promise<API.CreateDownloadResponse> => {
        return apiPost({
            path: `/decks/${deckId}/download`,
            body: {
                executionId,
            },
        });
    };

    getDeckStatuses = async (
        body: API.GetDeckStatusRequest = {},
        pagination: Pagination,
        dateRange: DateRange
    ): Promise<API.GetDeckStatusResponse> => {
        const response: API.GetReportStatusResponse = await apiPost({
            path: '/reportStatus',
            searchParams: new URLSearchParams({
                pageSize: pagination.pageSize.toString(),
                pageToken: pagination.pageToken.toString(),
                startDate: format(dateRange.startDate, DATE_FORMAT),
                endDate: format(dateRange.endDate, DATE_FORMAT),
            }),
            body,
        });

        const deckStatuses: DeckStatus[] = response.data.map(
            (reportStatus: ReportStatus, index) => {
                const {
                    deckId,
                    parameters,
                    deckName,
                    status,
                    step,
                    updateTimestamp,
                    executionId,
                } = reportStatus;
                return {
                    id: index,
                    deckId: parseInt(deckId),
                    name: parameters['name'] || deckName,
                    description: parameters['description'],
                    deckName,
                    status,
                    step,
                    executionId,
                    updateTimestamp: formatDeckStatusDateTime(
                        new Date(updateTimestamp)
                    ),
                    parameters,
                };
            }
        );
        return Promise.resolve({
            ...response,
            data: deckStatuses,
        });
    };

    createDeck = async (
        request: API.CreateDeckRequest
    ): Promise<API.CreateDeckResponse> => {
        return apiPost({
            path: `/domains/${request.domainId}/decks/${request.deckId}`,
            body: request,
        });
    };

    getPrograms = async (
        body: API.GetProgramsRequest = {},
        pagination: Pagination
    ): Promise<API.GetProgramsResponse> => {
        return apiPost({
            path: '/programs',
            searchParams: new URLSearchParams({
                pageSize: pagination.pageSize.toString(),
                pageToken: pagination.pageToken.toString(),
            }),
            body,
        });
    };
}
