import React from 'react';
import ReactMarkdown from 'react-markdown';
import { DimensionConfiguration, DimensionTemplate } from 'types/Dimensions';

export interface MarkdownDimensionConfiguration extends DimensionConfiguration {
    md: string;
}

export interface MarkdownDimensionProps extends DimensionTemplate {
    configuration: MarkdownDimensionConfiguration;
}

export const MarkdownDimension: React.FunctionComponent<
    MarkdownDimensionProps
> = (props) => {
    return (
        <div>
            <ReactMarkdown linkTarget="_blank">
                {props.configuration.md}
            </ReactMarkdown>
        </div>
    );
};
