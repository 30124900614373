export interface RequestParameters {
    [key: string]: string;
}

export interface InputParameterFilter {
    key: string;
    dimension: string;
    condition: Comparator;
}

export interface OrderBy {
    key: string;
    sort: Sort;
}

export enum Sort {
    ASC = 'ASC',
    DESC = 'DESC',
}

export interface Filter {
    key: string;
    value: string;
    comparator: Comparator;
}

export enum Comparator {
    EQUALS = 'EQUALS',
    ILIKE = 'ILIKE',
    NOT_EQUALS = 'NOT_EQUALS',
    GE = 'GE',
    GT = 'GT',
    LE = 'LE',
    LT = 'LT',
}

export type S3Location = {
    bucket: string;
    key: string;
};
