import { JsonValueHandler, JsonValueConfigurations } from './json';

export enum ValueTypes {
    NONE = 'none',
    JSON = 'json',
}

export interface BaseValueConfiguration {
    type: ValueTypes;
}

export type ValueConfiguration = JsonValueConfigurations;

export const ValueHandlerMap: {
    [key in ValueTypes]: (
        id: string,
        input: any,
        settings: ValueConfiguration
    ) => void;
} = {
    [ValueTypes.NONE]: () => {},
    [ValueTypes.JSON]: JsonValueHandler,
};
