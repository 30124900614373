import {
    getCdbInputTableData,
    CdbInputTableConfiguration,
} from './cdbInputTables';
export enum DataSource {
    CDB_INPUT_TABLE = 'cdb_input_table',
}

export interface BaseDataSourceConfiguration {
    type: DataSource;
    settings: any;
}

export type DataSourceConfiguration = CdbInputTableConfiguration;

export const DataSourceGetterMap: {
    [key in DataSource]: (...args: any[]) => Promise<any>;
} = {
    [DataSource.CDB_INPUT_TABLE]: getCdbInputTableData,
};
