import { Divider } from '@amzn/storm-ui';
import { useMountEffect } from '@react-hookz/web';
import React from 'react';
import { useDimensionStatusStore } from 'store/dimension.status';
import { useDimensionValueStore } from 'store/dimension.value';
import styled from 'styled-components';
import {
    Dimension,
    DimensionTemplate,
    DimensionTemplates,
    DimensionTypes,
    DimensionInitIgnore,
} from 'types/Dimensions';
import DropdownDimension from './DropdownDimension';
import { InputDimension } from './InputDimension';
import { StaticDropdownDimension } from './StaticDropdownDimension';
import { DateDimension } from './DateDimension';
import { MarkdownDimension } from './MarkdownDimension';
import { DataDimension } from './DataDimension';
import { useUnmount } from 'react-use';

const DimensionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-width: 500px;
`;

const DimensionComponentTypeMapper: {
    [key in DimensionTypes]: React.FunctionComponent<any>;
} = {
    [DimensionTypes.INPUT]: InputDimension,
    [DimensionTypes.DROPDOWN]: DropdownDimension,
    [DimensionTypes.STATIC_DROPDOWN]: StaticDropdownDimension,
    [DimensionTypes.DATE]: DateDimension,
    [DimensionTypes.MARKDOWN]: MarkdownDimension,
    [DimensionTypes.DATA]: DataDimension,
};

export interface DimensionTemplateBuilderProps {
    dimensionSections?: DimensionTemplates[][];
}

export const DimensionTemplateBuilderHelper = {
    getDimensionIds: (
        dimensionsSection: DimensionTemplates[][]
    ): Dimension[] => {
        const dimensions: Dimension[] = [];
        for (const section of dimensionsSection) {
            for (const dimensionDefinition of section) {
                if (!DimensionInitIgnore.includes(dimensionDefinition.type))
                    dimensions.push(dimensionDefinition.id);
            }
        }
        return dimensions;
    },

    DimensionGroup: ({ templates }: { templates: DimensionTemplates[] }) => {
        return (
            <DimensionsContainer>
                {templates.map((template) => (
                    <DimensionTemplateBuilderHelper.DimensionComponent
                        key={template.id}
                        {...template}
                    />
                ))}
            </DimensionsContainer>
        );
    },

    DimensionComponent: (props: DimensionTemplate) => {
        const Component = DimensionComponentTypeMapper[props.type];
        return <Component {...props} />;
    },
};

export const DimensionTemplateBuilder: React.FunctionComponent<
    DimensionTemplateBuilderProps
> = (props) => {
    const { dimensionSections = [] } = props;
    const dimensionIds =
        DimensionTemplateBuilderHelper.getDimensionIds(dimensionSections);
    const [initDimensionsData, clearAllValues] = useDimensionValueStore(
        (state) => [state.initDimensions, state.clearAllValues]
    );
    const [initDimensionsStatus, clearStatus] = useDimensionStatusStore(
        (state) => [state.initDimensions, state.clearStatus]
    );
    useMountEffect(() => {
        initDimensionsData(dimensionIds);
        initDimensionsStatus(dimensionIds);
    });
    useUnmount(() => {
        clearAllValues();
        clearStatus();
    });
    return (
        <>
            {dimensionSections.map((dimensions, index) => (
                <div key={index}>
                    <DimensionTemplateBuilderHelper.DimensionGroup
                        templates={dimensions}
                    />
                    {index < dimensionSections.length - 1 && <Divider />}
                </div>
            ))}
        </>
    );
};
