import { Alert as StormAlert, Text, InlineExpander } from '@amzn/storm-ui';
import styled from 'styled-components';
import React from 'react';
import { AlertType, useAlertStore } from 'store/alert';
import { useToggle } from '@react-hookz/web';
import StackTracey from 'stacktracey';

const AlertContainer = styled.div`
    padding: 10px 0px;
    z-index: 1000;
`;

export const Alert: React.FunctionComponent = () => {
    const [alertState, resetAlert] = useAlertStore((state) => [
        state,
        state.resetAlert,
    ]);

    const [isStackTraceOpen, toggleStackTrace] = useToggle(false);

    return (
        <AlertContainer>
            <StormAlert
                type={alertState.type}
                isOpen={alertState.message.length > 0}
                withCloseButton
                onClose={resetAlert}
            >
                <Text>{alertState.message}</Text>
                {alertState.type === AlertType.ERROR ? (
                    <div>
                        <InlineExpander
                            label={
                                isStackTraceOpen
                                    ? 'Hide Details'
                                    : 'Show Details'
                            }
                            handleClick={toggleStackTrace}
                            isOpen={isStackTraceOpen}
                        >
                            <pre data-testid="alertDetails">
                                {new StackTracey(alertState.stack)
                                    .withSources()
                                    .asTable()}
                            </pre>
                        </InlineExpander>
                    </div>
                ) : (
                    ''
                )}
            </StormAlert>
        </AlertContainer>
    );
};

export default Alert;
