import * as React from 'react';
import { Card, Divider, Text, TextButton } from '@amzn/storm-ui';
import styled from 'styled-components';

import { getDisplayStatus } from 'utils/status.utils';
import { DeckStatus, DisplayStatus } from 'types/Status';
import { createDownload } from 'retrievers/api';
import { AlertType, useAlertStore } from 'store/alert';
import { DisplayStatusText } from 'components/home/DisplayStatusText';
import { useToggle } from '@react-hookz/web';
import { prettyPrintParameters } from 'utils/app.utils';
import {
    DetailPopover,
    DetailPopoverViewer,
} from 'components/common/DetailPopover';

const SpacedApart = styled('div')`
    display: flex;
    justify-content: space-between;
`;

const DeckStatusCardContainer = styled(Card)`
    border: 1px solid ${({ theme }) => theme.palette.blue[100]};
    height: 100%;
    box-sizing: border-box;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const TruncateText = styled(Text)`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

const NameAndDescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    height: 30%;
`;

const AdditionalDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    height: 25%;
`;
const StatusAndButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: end;
    height: 30%;
`;

export interface DeckStatusCardProps extends DeckStatus {
    readonly parametersPosition: 'top' | 'bottom';
}

export const DeckStatusCard: React.FunctionComponent<DeckStatusCardProps> = (
    props: DeckStatusCardProps
) => {
    const [alertState] = useAlertStore((state) => [state]);
    const [isToggled, toggle] = useToggle(false);

    const {
        name,
        description,
        deckName,
        status,
        step,
        executionId,
        parameters,
        updateTimestamp,
        id,
        deckId,
        parametersPosition,
    } = props;

    const handleDownload = async (): Promise<void> => {
        try {
            const response = await createDownload(deckId, executionId);
            const url = response.presignedUrl;
            window.open(url, '_blank');
        } catch (e: any) {
            alertState.setAlert(e.message, AlertType.ERROR, e.stack);
        }
    };

    const ParametersViewer: React.FunctionComponent = () => {
        return (
            <DetailPopoverViewer>
                {[
                    prettyPrintParameters({ executionId }),
                    ...prettyPrintParameters(parameters),
                ].map((parameter, i) => (
                    <Text key={i} fontSize="mini">
                        {parameter}
                    </Text>
                ))}
            </DetailPopoverViewer>
        );
    };

    const displayStatus = getDisplayStatus(status, step);
    return (
        <DeckStatusCardContainer>
            <NameAndDescriptionContainer>
                <Text
                    data-testid={`deckStatusCardName-${id}`}
                    type="h3"
                    textColor="base"
                >
                    {name}
                </Text>
                <TruncateText
                    data-testid={`deckStatusCardDescription-${id}`}
                    type="h4"
                    textColor="secondary"
                >
                    {description}
                </TruncateText>
            </NameAndDescriptionContainer>
            <br />
            <AdditionalDetailsContainer>
                <TruncateText
                    data-testid={`deckStatusCardDeckName-${id}`}
                    type="p"
                    textColor="secondary"
                >
                    <strong>Deck Name: </strong>
                    {deckName}
                </TruncateText>
                {parameters.advertiser_name !== undefined && (
                    <TruncateText
                        data-testid={`deckStatusCardAdvertiser-${id}`}
                        type="p"
                        textColor="secondary"
                    >
                        <strong>Advertiser: </strong>
                        {parameters.advertiser_name}
                    </TruncateText>
                )}
                {parameters.locale !== undefined && (
                    <TruncateText
                        data-testid={`deckStatusCardLocale-${id}`}
                        type="p"
                        textColor="secondary"
                    >
                        <strong>Locale: </strong>
                        {parameters.locale}
                    </TruncateText>
                )}
            </AdditionalDetailsContainer>
            <br />
            <StatusAndButtonsContainer>
                <Text
                    data-testid={`deckStatusCardStatus-${id}`}
                    type="p"
                    textColor="secondary"
                >
                    Status:{' '}
                    {<DisplayStatusText displayStatus={displayStatus} />} |
                    Created: {updateTimestamp}
                </Text>
                <Divider />
                <SpacedApart>
                    <TextButton
                        data-testid={`deckStatusCardDownload-${id}`}
                        onClick={handleDownload}
                        disabled={displayStatus !== DisplayStatus.SUCCESS}
                    >
                        Download
                    </TextButton>
                    <DetailPopover
                        isOpen={isToggled}
                        open={toggle}
                        label="Parameters"
                        position={parametersPosition}
                        renderInPortalDiv={true}
                    >
                        <ParametersViewer />
                    </DetailPopover>
                </SpacedApart>
            </StatusAndButtonsContainer>
        </DeckStatusCardContainer>
    );
};
