import React from 'react';

import {
    VisualizationComponentProps,
    VisualizationTypes,
} from './formatHandler';
import { JSONPath } from 'jsonpath-plus';

import { JsonView, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

import { ComponentLabel } from 'components/common/Styles';
import { Alert, Text } from '@amzn/storm-ui';

export interface JsonVisualizationConfiguration {
    type: VisualizationTypes.JSON;
    settings: {
        jsonPath?: string;
        parseJsonPathValue?: boolean;
    };
}

export interface JsonVisualizationProps
    extends VisualizationComponentProps,
        JsonVisualizationConfiguration {}

export const JsonVisualization: React.FunctionComponent<
    JsonVisualizationProps
> = (props) => {
    const { input, label, settings } = props;
    let data =
        settings.jsonPath !== undefined
            ? JSONPath({
                  path: settings.jsonPath,
                  wrap: false,
                  json: input,
              })
            : input;

    if (settings.parseJsonPathValue) {
        try {
            data = JSON.parse(data);
        } catch {
            return (
                <div>
                    <ComponentLabel>{label}</ComponentLabel>
                    <Alert
                        type="error"
                        isSolid={true}
                        withCloseButton={false}
                        hideIcon={false}
                        inline={false}
                    >
                        <Text>Error in parsing json</Text>
                    </Alert>
                </div>
            );
        }
    }

    return (
        <div>
            <ComponentLabel>{label}</ComponentLabel>
            <JsonView data={data} style={defaultStyles} />
        </div>
    );
};
