/**
 * formats date in DDD MMM DD YYYY HH:MM:SS format
 */
export const formatDeckStatusDateTime = (date: Date): string => {
    return date.toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        weekday: 'short',
        hour12: false,
    });
};

export const createDate = (
    days: number = 0,
    months: number = 0,
    years: number = 0
) => {
    const date = new Date();
    date.setDate(date.getDate() + days);
    date.setMonth(date.getMonth() + months);
    date.setFullYear(date.getFullYear() + years);
    return date;
};

export const DATE_FORMAT = 'yyyy-MM-dd';
