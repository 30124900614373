import { devSettings } from 'config/developer';
import { AuthSettings } from 'store/auth';
import { APPENV } from 'utils/app.utils';
import { getSettingsOrigin } from 'endpoint/settings';

export const getWebsiteSettings = async (): Promise<AuthSettings> => {
    if (APPENV.mock_server) {
        return {
            apiEndpoint: 'localhost:3100',
            adsApiEndpoint: 'localhost:3100',
            cognitoPoolId: '',
            region: '',
        };
    }
    try {
        const { host, retriever } = getSettingsOrigin();
        const response = await fetch(`https://${host}/settings.json`, {
            method: 'GET',
            credentials: 'include',
        });
        if (response.ok) {
            try {
                const responseJson = await response.json();
                // Sometimes when developing in dev, the /settings.json file is an HTML file
                // This ensures the response is a valid JSON
                const resp = JSON.parse(JSON.stringify(responseJson));
                return Promise.resolve({ ...resp, retriever });
            } catch (e) {
                return Promise.resolve(devSettings);
            }
        } else {
            return Promise.resolve({
                ...devSettings,
                retriever,
            });
        }
    } catch (e) {
        if (devSettings.apiEndpoint.length === 0) {
            throw new Error('Issue getting website settings');
        } else {
            return Promise.resolve(devSettings);
        }
    }
};
