import * as React from 'react';
import { CardListItem, Text, RadioButton } from '@amzn/storm-ui';
import styled from 'styled-components';

import { Deck } from 'types/Deck';

const BlueText = styled(Text)`
    color: ${({ theme }) => theme.palette.blue[600]};
`;

const RadioButtonContainer = styled.div`
    display: flex;
    flex: 0 0 5%;
`;

const ObjectiveCardInformationContainer = styled.div`
    display: table;
    flex: 0 0 90%;
`;

const ObjectivesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export interface ObjectivesCardProps extends Deck {
    handleDeckSelect: (id: number) => void;
    isSelected: boolean;
}

export const ObjectivesCard: React.FunctionComponent<ObjectivesCardProps> = (
    props: ObjectivesCardProps
) => {
    const { id, name, description, recommended, handleDeckSelect, isSelected } =
        props;

    return (
        <CardListItem
            onClick={() => handleDeckSelect(id)}
            isSelected={isSelected}
            id={`objectivesCard-${id}`}
            ariaLabel={`objectivesCardName-${id}`}
        >
            <ObjectivesContainer>
                <RadioButtonContainer>
                    <RadioButton
                        data-testid={`deckCardButton-${id}`}
                        id={id.toString()}
                        isChecked={isSelected}
                        onChange={(e) => {
                            handleDeckSelect(Number(e.target.value));
                        }}
                        value={id.toString()}
                    />
                </RadioButtonContainer>
                <ObjectiveCardInformationContainer>
                    {recommended && (
                        <BlueText
                            data-testid={`deckCardRecommended-${id}`}
                            type="h5"
                        >
                            RECOMMENDED
                        </BlueText>
                    )}
                    <Text data-testid={`deckCardName-${id}`} type="h3">
                        {name}
                    </Text>
                    <Text
                        data-testid={`deckCardDescription-${id}`}
                        type="p"
                        textColor="secondary"
                    >
                        {description}
                    </Text>
                </ObjectiveCardInformationContainer>
            </ObjectivesContainer>
        </CardListItem>
    );
};
