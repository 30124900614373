/**
 * @param clientId client id to use for the Identity Provider (traditionally the current host)
 * @param redirectUri url to redirect to after signing in (traditionally the current url)
 */
export interface MidwayClientConfig {
    clientId: string;
    redirectUri: string;
}

export async function getToken(config: MidwayClientConfig): Promise<string> {
    try {
        const token = await fetch(buildSSOUrl(config), {
            credentials: 'include',
        });
        if (token.status !== 200) {
            throw new Error(`Failed to get token from Midway: ${token.status}`);
        }
        return token.text();
    } catch (e) {
        // ignore exception for now, no redirect to Midway from here
        return '';
    }
}

//
//
// Utility functions and types
//
//

export interface ToSignRequest {
    method: string;
    url: string;
    headers: { [key: string]: string };
    params: { [key: string]: string };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
}

interface RedirectParams {
    client_id: string;
    redirect_uri: string;
    response_type: string;
    scope: string;
    nonce: string;
}

export function buildSSOUrl({
    clientId,
    redirectUri,
}: MidwayClientConfig): string {
    const queryParams: RedirectParams = {
        response_type: 'id_token',
        client_id: encodeURIComponent(clientId),
        redirect_uri: encodeURIComponent(redirectUri),
        scope: 'openid',
        nonce: generateNonce(),
    };

    return `https://midway-auth.amazon.com/SSO${buildQuery(queryParams)}`;
}

function buildQuery(parameters: RedirectParams): string {
    return Object.keys(parameters).reduce((accumulator, key: string) => {
        const nextValue = parameters[key as keyof RedirectParams];
        return `${accumulator + key}=${nextValue}&`;
    }, '?');
}

function generateNonce(): string {
    const characterSet =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    return Array.from(new Array(64), () =>
        characterSet.charAt(Math.floor(Math.random() * characterSet.length))
    ).join('');
}
