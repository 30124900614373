import { useEffect, useMemo } from 'react';

import 'react-json-view-lite/dist/index.css';

import { useSetDimensionValue } from 'utils/dimension.hooks';
import { DataDimensionValue } from 'store/dimension.value';
import { buildRequestParameter } from 'utils/app.utils';
import { BaseValueConfiguration, ValueTypes } from './formatHandler';
import { JSONPath } from 'jsonpath-plus';
import { isEmpty } from 'lodash';

export interface JsonValueConfigurations extends BaseValueConfiguration {
    type: ValueTypes.JSON;
    settings: {
        jsonPath?: string;
    };
}

export function JsonValueHandler(
    id: string,
    input: any,
    { settings }: JsonValueConfigurations
) {
    const data = useMemo(() => {
        if (isEmpty(input)) {
            return {};
        }
        return settings.jsonPath !== undefined
            ? JSONPath({
                  path: settings.jsonPath,
                  wrap: false,
                  json: input,
              })
            : input;
    }, [input]);

    const keyMap: Record<string, string> = {};
    Object.keys(data).forEach((key: string) => {
        keyMap[key] = key;
    });
    const rpMap = { value: `${id}`, ...keyMap };

    const setDimensionValue = useSetDimensionValue<DataDimensionValue>(
        id,
        buildRequestParameter(rpMap)
    )[1];

    useEffect(() => {
        setDimensionValue({ value: JSON.stringify(data), ...data });
    }, [data]);
}
