import { zustandStore } from 'store/zustand.helpers';
import { Cti } from 'types/Cti';

export interface CtiState {
    cti?: Cti;
}

export interface CtiActions {
    setCti: (cti: Cti) => void;
    resetCti: () => void;
}

export const initialState: CtiState = { cti: undefined };

export const { store: ctiStore, useStore: useCtiStore } = zustandStore<
    CtiState,
    CtiActions
>('Cti', initialState, (set) => ({
    setCti: (cti: Cti) =>
        set(() => ({
            cti,
        })),
    resetCti: () => set(() => ({ ...initialState })),
}));
