import { get } from 'lodash';

const getWindowValue = (valuePath: string) => {
    const result = get(window, valuePath);
    return result;
};

const getEntityId = () => getWindowValue('ANC_CONSTANTS.entityId');
const getMarketplaceId = () => getWindowValue('ANC_CONSTANTS.marketplaceId');
const getCsrfToken = () => getWindowValue('ANC_CONSTANTS.csrfToken');
const isImpersonator = () =>
    getWindowValue('ANC_CONSTANTS.isImpersonator') === 'true';

interface AdsApiClientParams {
    clientId: string;
    endpoint: string;
    marketplaceId: string;
    entityId: string;
    csrfToken: string;
    isImpersonator: boolean;
}

export const adsApiClientParam = (
    endpoint: string,
    requesterAlias: string
): AdsApiClientParams => ({
    clientId: requesterAlias,
    endpoint,
    marketplaceId: getMarketplaceId(),
    entityId: getEntityId(),
    csrfToken: getCsrfToken(),
    isImpersonator: isImpersonator(),
});
