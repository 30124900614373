import React from 'react';
import styled from 'styled-components';
import { InView } from 'react-intersection-observer';
import {
    Dropdown,
    DropdownItem,
    DropdownProps,
    InlineMessage,
    SearchInput,
} from '@amzn/storm-ui';
import { LabelEndComponent } from './LabelEndComponent';
import ReactMarkdown from 'react-markdown';

const LoadingDiv = styled.div`
    justify-content: center;
    display: flex;
    role: alert;
    aria-live: assertive;
`;

const StickyDiv = styled.div`
    position: sticky;
    top: -3px;
    margin-top: -3px;
    padding: 5px;
    z-index: 100;
    background: white;
`;

export interface DropdownComponentProps {
    dimension: string;
    loading?: boolean;
    showItemsWhileLoading?: boolean;
    search: string;
    items: DropdownItemData[];
    onChange: (e: string) => void;
    onEndScroll?: () => void;
    setSearch: (e: string) => void;
    label: string;
    selectedValue: string;
    tooltip?: string;
    disabled?: boolean;
    showSearchInput?: boolean;
    optional?: boolean;
    missingText?: string;
}

export interface DropdownItemData {
    value: string;
    label: string;
    [key: string]: string; // used to store auxilary data related to the item
}

enum DropdownItemPosition {
    FIRST = 'first',
    LAST = 'last',
    MIDDLE = 'middle',
}

export class DropdownComponentHelpers {
    static renderItems(
        items: DropdownItemData[],
        loading: boolean,
        onEndScroll?: () => void,
        missingText?: string
    ) {
        const renderItems: JSX.Element[] =
            items.length === 0 && !loading
                ? [
                      <DropdownItem key="no-items" value="n/a" disabled>
                          <ReactMarkdown linkTarget="_blank">
                              {missingText ?? 'no items available'}
                          </ReactMarkdown>
                      </DropdownItem>,
                  ]
                : items.map(({ label, value }, index) => (
                      <DropdownItem key={`item-${index}`} value={value}>
                          <DropdownComponentHelpers.renderDropdownLabel
                              onEndScroll={onEndScroll}
                              label={label || value}
                              position={
                                  (index === items.length - 1 &&
                                      DropdownItemPosition.LAST) ||
                                  DropdownItemPosition.MIDDLE
                              }
                          />
                      </DropdownItem>
                  ));
        return { renderItems };
    }

    static renderDropdownLabel: React.FunctionComponent<{
        label: string;
        position: DropdownItemPosition;
        onEndScroll?: () => void;
    }> = ({ label, position, onEndScroll }) => {
        switch (position) {
            case DropdownItemPosition.LAST:
                return (
                    <InView
                        as="span"
                        onChange={(inView) =>
                            inView && onEndScroll && onEndScroll()
                        }
                    >
                        {label}
                    </InView>
                );
            default:
                return <span>{label}</span>;
        }
    };
}

export const DropdownComponent: React.FunctionComponent<
    DropdownComponentProps
> = (props) => {
    const {
        loading = false,
        showItemsWhileLoading = true,
        dimension,
        search,
        setSearch,
        items,
        onEndScroll,
        onChange,
        label,
        selectedValue,
        disabled,
        tooltip,
        showSearchInput = true,
        optional,
        missingText,
    } = props;
    const { renderItems } = DropdownComponentHelpers.renderItems(
        items,
        loading,
        onEndScroll,
        missingText
    );
    const conditionalParameters = {} as DropdownProps;
    const labelEndProps = { tooltip, optional };
    conditionalParameters.renderLabelEnd = (
        <LabelEndComponent {...labelEndProps} />
    );
    return (
        <Dropdown
            label={label}
            onOverrideLabel={(item, value) => (value && item) || ''}
            selectedValue={selectedValue}
            data-testid={`${dimension}-dropdown`}
            preRender={() => (
                <StickyDiv>
                    {showSearchInput && (
                        <SearchInput
                            id={`${dimension}-search`}
                            data-testid={`${dimension}-search`}
                            onClear={() => {
                                setSearch('');
                            }}
                            value={search}
                            fullWidth
                            onChange={(e) => {
                                setSearch(e.target.value);
                            }}
                        />
                    )}
                </StickyDiv>
            )}
            postRender={() =>
                loading && (
                    <LoadingDiv>
                        <InlineMessage
                            message="Loading"
                            data-testid="dropdown-loading"
                            style={{ textAlign: 'center' }}
                        />
                    </LoadingDiv>
                )
            }
            onChange={onChange}
            disabled={disabled}
            fullWidth
            {...conditionalParameters}
        >
            {(loading && showItemsWhileLoading && renderItems) ||
                (!loading && renderItems)}
        </Dropdown>
    );
};
